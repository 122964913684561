// @ts-check
import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, ListItemSecondaryAction } from '@material-ui/core';

import Divider from '@material-ui/core/Divider/Divider';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1)
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  hubLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  }
}));

function Branches({ branches }) {
  const classes = useStyles();
  const history = useHistory();

  const n = branches.length - 1;
  const parties = branches.map((branch, index) => {
    return (
      <div key={index}>
        <ListItem button onClick={() => history.push(`/branches/${branch.id}/view`)}>
          <ListItemText primary={`${branch.name}`} secondary={branch.address}></ListItemText>

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={() => history.push(`/branches/${branch.id}/view`)}
            >
              <VisibilityIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {index !== n && <Divider />}
      </div>
    );
  });

  return (
    <>
      <List className={classes.root}>{parties}</List>
    </>
  );
}

function BranchesInHub({ branches }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {branches.length === 0 && <small>no branches</small>}
      {branches.length > 0 && <Branches branches={branches} />}
    </div>
  );
}

export function BranchesInHubComponent({ hub }) {
  const classes = useStyles();

  return (
    <div className={classes.margin}>
      <>
        {/* <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
          <small>
            <b>Branches</b>
          </small>
        </Typography> */}

        <BranchesInHub branches={hub.branches} />
      </>
    </div>
  );
}
