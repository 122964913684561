// @ts-check
import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Loader as MapLoader } from 'google-maps';
import { useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

import { MenuItem } from '@material-ui/core';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  map: {
    marginTop: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

let markers = [];

// @ts-ignore
const TrackingMapComponent = ({ branches }) => {
  const classes = useStyles();

  const mapRef = useRef(null);

  const [map, setMap] = useState(undefined);
  const [google, setGoogle] = useState(null);
  const [loaded, setLoaded] = useState(false);

  // const [markers, setMarkers] = useState([]);

  const mapping = useCallback(async () => {
    const geopoint = { lat: -26.2041, lng: 28.0473 };

    const options = {
      libraries: ['places', 'geometry']
    };
    const loader = new MapLoader(GOOGLE_MAPS_API_KEY, options);
    const google = await loader.load();
    setLoaded(true);

    if (!loaded || !mapRef.current) {
      return;
    }

    // @ts-ignore
    const map = new google.maps.Map(mapRef.current, {
      center: geopoint,
      zoom: 10,
      scaleControl: false,
      mapTypeControl: false,
      fullscreenControl: true,
      streetViewControl: false
    });

    // @ts-ignore
    setMap(map);
    // @ts-ignore
    setGoogle(google);
  }, [loaded]);

  useEffect(() => {
    mapping();
  }, [mapping]);

  useEffect(() => {
    const clearMarkers = () => {
      for (let i = 0; i < markers.length; i++) {
        // @ts-ignore
        markers[i].setMap(null);
      }

      markers = [];
    };

    if (loaded && google && branches.length >= 0) {
      clearMarkers();

      if (branches.length > 0) {
        const _markers = [];
        // @ts-ignore
        const boundary = google.maps;
        let bounds = new boundary.LatLngBounds();
        for (const branch of branches) {
          // @ts-ignore
          const marker = new google.maps.Marker({
            map: map,
            // icon: icon,
            // @ts-ignore
            anchorPoint: new google.maps.Point(0, 0),
            draggable: false,
            // @ts-ignore
            animation: google.maps.Animation.DROP
            // label: { color: '#00aaff', fontWeight: 'bold', fontSize: '14px', text: 'Your text here' }
          });

          const geopoint = { lat: branch.location.latitude, lng: branch.location.longitude };

          marker.setPosition(geopoint);
          // marker.setVisible(true);

          _markers.push(marker);

          bounds.extend(geopoint);
        }

        // @ts-ignore
        markers = _markers;

        // @ts-ignore
        map.fitBounds(bounds);
        // @ts-ignore
        map.panToBounds(bounds);
      }
    }
  }, [loaded, google, map, branches]);

  return (
    <>
      <div className={classes.map}>
        <div>
          <div
            id="map"
            // @ts-ignore
            ref={mapRef}
            style={{ height: '25em', width: '100%' }}
          ></div>
        </div>
      </div>
    </>
  );
};

export function HomeMapComponent() {
  const [branches, setBranches] = useState([]);
  const { hubs } = useSelector((state) => state.hubs);

  const hubs_list = hubs.map((hub) => ({ value: hub.id, label: hub.name }));

  const handleChange = (event) => {
    // setHub(event.target.value);

    const hub = hubs.find((hub) => hub.id === event.target.value);
    setBranches(hub.branches);
  };

  return (
    <div>
      <TextField
        // {...formikProps('hub')}
        id="outlined-select-currency"
        select
        label="Hub"
        variant="outlined"
        onChange={handleChange}
        fullWidth={true}
      >
        {hubs_list.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TrackingMapComponent branches={branches} />
    </div>
  );
}
