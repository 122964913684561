// @ts-check
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';

import Button from '@material-ui/core/Button/Button';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { grantAdminRightsAction, revokeAdminRightsAction } from '../redux/actions/user-actions';

import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2)
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  granted: {
    color: '#4caf50'
  }
}));

export function AdminPermissionsComponent({ user }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Permission updated'
  });

  const showSnackbar = (status = 'success', description = 'Permission updated') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const grant = () => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(grantAdminRightsAction(user.id, props));
  };

  const revoke = () => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(revokeAdminRightsAction(user.id, props));
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemIcon>
            {user.permissions.administrator && (
              <FiberManualRecordIcon className={classes.granted} />
            )}
            {!user.permissions.administrator && <FiberManualRecordIcon color="error" />}
          </ListItemIcon>
          <ListItemText primary={<small>Admin rights</small>} secondary={null}></ListItemText>

          <ListItemSecondaryAction>
            {user.permissions.administrator && (
              <Button
                type="button"
                variant="outlined"
                size="small"
                color="secondary"
                onClick={revoke}
              >
                Revoke
              </Button>
            )}
            {!user.permissions.administrator && (
              <Button
                type="button"
                variant="contained"
                size="small"
                color="secondary"
                onClick={grant}
              >
                Grant
              </Button>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <>
        <Backdrop
          className={classes.backdrop}
          open={backdrop}
          invisible={false}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </>
  );
}
