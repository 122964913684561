import { Hub } from '../../../hubs/domain/entities/hub-entity';

export class Region {
  id: string;
  name: string;
  hubs: Hub[];

  /**
   * Region entity constructor
   * @constructor
   * @param region
   * @param hubs
   */
  constructor(region: any, hubs: any[] = []) {
    this.id = region.id;
    this.name = region.name;
    this.hubs = hubs && hubs.map((hub: any) => new Hub(hub));
  }
}
