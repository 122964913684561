import { Dispatch } from 'redux';
import { RegionRepositoryImpl } from '../../../data/repositories/region-repository-impl';
import { RegionUsecaseImpl } from '../../../domain/usecases/region-usecase';

import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS
} from '../../../../../core/redux/types/loading-types';

import {
  REGION_LOAD_FAILURE,
  REGION_LOAD_REQUEST,
  REGION_LOAD_SUCCESS
} from '../types/region-types';
import {
  REGIONS_LOAD_FAILURE,
  REGIONS_LOAD_REQUEST,
  REGIONS_LOAD_SUCCESS
} from '../types/regions-types';

export const getRegionsAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: REGIONS_LOAD_REQUEST });

    try {
      const regionRepository = new RegionRepositoryImpl();
      const regionUsecase = new RegionUsecaseImpl(regionRepository);

      const result = await regionUsecase.GetRegions();

      dispatch({ type: REGIONS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      console.log(error);
      dispatch({ type: REGIONS_LOAD_FAILURE, error });
    }
  };
};

export const getRegionAction = (region_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: REGION_LOAD_REQUEST });

    try {
      const regionRepository = new RegionRepositoryImpl();
      const regionUsecase = new RegionUsecaseImpl(regionRepository);

      const result = await regionUsecase.GetRegion(region_id);

      dispatch({ type: REGION_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: REGION_LOAD_FAILURE, error });
    }
  };
};

export const createRegionAction = (name: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: REGION_LOAD_REQUEST });

    try {
      const regionRepository = new RegionRepositoryImpl();
      const regionUsecase = new RegionUsecaseImpl(regionRepository);

      const result = await regionUsecase.CreateRegion(name);

      dispatch({ type: REGION_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.history.push(`/regions`);
    } catch (error) {
      dispatch({ type: REGION_LOAD_FAILURE, error });
    }
  };
};

export const editRegionAction = (region_id: string, name: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const regionRepository = new RegionRepositoryImpl();
      const regionUsecase = new RegionUsecaseImpl(regionRepository);

      const result = await regionUsecase.EditRegion(region_id, name);

      dispatch({ type: REGION_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const addHubToRegionAction = (region_id: string, hub_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const regionRepository = new RegionRepositoryImpl();
      const regionUsecase = new RegionUsecaseImpl(regionRepository);

      const result = await regionUsecase.AddHub(region_id, hub_id);

      dispatch({ type: REGION_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
      props.showSnackbar();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const removeHubFromRegionAction = (region_id: string, hub_id: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const regionRepository = new RegionRepositoryImpl();
      const regionUsecase = new RegionUsecaseImpl(regionRepository);

      const result = await regionUsecase.RemoveHub(region_id, hub_id);

      dispatch({ type: REGION_LOAD_SUCCESS, payload: result });
      props.closeBackdrop();
      props.showSnackbar();
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar('error', error.message);
    }
  };
};
