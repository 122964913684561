import {
  BRANCHES_LOAD_REQUEST,
  BRANCHES_LOAD_SUCCESS,
  BRANCHES_LOAD_FAILURE
} from '../types/branches-types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  branches: []
};

function branches(state = initialState, action: any = null) {
  switch (action.type) {
    case BRANCHES_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case BRANCHES_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case BRANCHES_LOAD_SUCCESS:
      return {
        ...state,
        branches: action.payload,
        success: true,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default branches;
