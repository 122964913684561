import {
  OrderEvents as OrderEventsDefault,
  TripEvents as TripEventsDefault,
  TaskEvents as TaskEventsDefault,
  DriverAppEvents as DriverEventsDefault,
  WalletEvents as WalletEventsDefault
} from '../../../../core/middleware/events';

class OrderEvents {
  collected: boolean;
  in_transit: boolean;
  arrived: boolean;
  delivered: boolean;
  abandoned: boolean;
  returned: boolean;
  cancelled: false;

  constructor(args) {
    this.collected = args.collected;
    this.in_transit = args.in_transit;
    this.arrived = args.arrived;
    this.delivered = args.delivered;
    this.abandoned = args.abandoned;
    this.returned = args.returned;
    this.cancelled = args.cancelled;
  }
}
class TripEvents {
  manual_assigned: boolean;
  auto_assigned: boolean;
  accepted: boolean;
  rejected: boolean;
  arrived: boolean;
  started: boolean;
  completed: boolean;
  cancelled: boolean;
  arrived_at_collection_point: boolean;
  in_progress: boolean;

  constructor(args) {
    this.manual_assigned = args.manual_assigned;
    this.auto_assigned = args.auto_assigned;
    this.accepted = args.accepted;
    this.rejected = args.rejected;
    this.arrived = args.arrived;
    this.started = args.started;
    this.completed = args.completed;
    this.cancelled = args.cancelled;
    this.arrived_at_collection_point = args.arrived_at_collection_point;
    this.in_progress = args.in_progress;
  }
}

class TaskEvents {
  started: boolean;
  completed: boolean;
  abandoned: boolean;
  returned: boolean;
  arrived: boolean;
  cancelled: boolean;

  constructor(args) {
    this.started = args.started;
    this.completed = args.completed;
    this.abandoned = args.abandoned;
    this.returned = args.returned;
    this.arrived = args.arrived;
    this.cancelled = args.cancelled;
  }
}

class DriverEvents {
  online: boolean;
  offline: boolean;
  onlunch: boolean;
  endlunch: boolean;

  constructor(args) {
    this.online = args.online;
    this.offline = args.offline;
    this.onlunch = args.onlunch;
    this.endlunch = args.endlunch;
  }
}
class WalletEvents {
  completed: boolean;

  constructor(args) {
    this.completed = args.completed;
  }
}
class Webhooks {
  location: string;
  general: string;
  order_events: OrderEvents;
  trip_events: TripEvents;
  task_events: TaskEvents;
  driver_events: DriverEvents;
  wallet_events: WalletEvents;

  constructor(
    location: string,
    general: string,
    order_events: any,
    trip_events: any,
    task_events: any,
    driver_events: any,
    wallet_events: any
  ) {
    console.log(order_events);
    this.location = location || '';
    this.general = general || '';
    this.order_events = new OrderEvents(order_events);
    this.trip_events = new TripEvents(trip_events);
    this.task_events = new TaskEvents(task_events);
    this.driver_events = new DriverEvents(driver_events);
    this.wallet_events = new WalletEvents(wallet_events);
  }
}

class Organization {
  id: string;
  name: string;
  logo: string;
  webhooks: Webhooks;
  allow_custom_payment_fields?: boolean;
  client_type: string;
  /**
   * Organization entity constructor
   * @constructor
   * @param {string} id
   * @param {string} name
   * @param {string} organization
   * @param {string} logo
   * @param {any} webhooks
   */
  constructor(
    id: string,
    name: string,
    logo: string,
    webhooks: any = undefined,
    client_type: string,
    allow_custom_payment_fields?: boolean
  ) {
    const {
      location,
      general,
      order_events,
      trip_events,
      task_events,
      driver_events,
      wallet_events
    } = webhooks || {};

    webhooks = {
      location: location || '',
      general: general || '',
      order_events: { ...OrderEventsDefault, ...order_events },
      trip_events: { ...TripEventsDefault, ...trip_events },
      task_events: { ...TaskEventsDefault, ...task_events },
      driver_events: { ...DriverEventsDefault, ...driver_events },
      wallet_events: { ...WalletEventsDefault, ...wallet_events }
    };
    this.id = id;
    this.name = name;
    this.logo = logo;
    this.webhooks = new Webhooks(
      webhooks.location,
      webhooks.general,
      webhooks.order_events,
      webhooks.trip_events,
      webhooks.task_events,
      webhooks.driver_events,
      webhooks.wallet_events
    );
    this.allow_custom_payment_fields = allow_custom_payment_fields || false;
    this.client_type = client_type;
  }
}

export class Me {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  mobile_no: string;
  image: string;
  organization: Organization;

  constructor(args: any) {
    this.id = args.id;
    this.firstname = args.firstname;
    this.lastname = args.lastname;
    this.email = args.email;
    this.mobile_no = args.mobile_no;
    this.image = args.image;
    this.organization = new Organization(
      args.organization.id,
      args.organization.name,
      args.organization.logo,
      args.organization.webhooks,
      args.organization.client_type,
      args.organization.allow_custom_payment_fields
    );
  }
}
