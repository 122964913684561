// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { ListItemSecondaryAction } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { EditUserFormComponent } from './edit-user-from-component';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

function UserInformation({ user }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              alt={`${user.firstname} ${user.lastname}`}
              src={user.image || '/static/images/avatar/1.jpg'}
            />
          </ListItemAvatar>
          <ListItemText
            primary={`${user.firstname} ${user.lastname}`}
            secondary={null}
          ></ListItemText>

          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
            <small>
              <b>Edit User</b>
            </small>
          </Typography>
        </>
      )}

      {open && <EditUserFormComponent user={user} handleClose={handleClose} />}
    </>
  );
}

export function UserInformationComponent({ user }) {
  return (
    <div>
      <UserInformation user={user} />
    </div>
  );
}
