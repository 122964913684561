// @ts-check
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FleetPermissionsComponent } from './fleet-permissions-component';
import { AdminPermissionsComponent } from './admin-permissions-component';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  heading: {
    margin: theme.spacing(2)
  }
}));

export function GeneralPermissionsComponent({ user }) {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
        <small>
          <b>Permissions</b>
        </small>
      </Typography>

      <AdminPermissionsComponent user={user} />
      <FleetPermissionsComponent user={user} />
    </div>
  );
}
