import {
  BRANCH_LOAD_REQUEST,
  BRANCH_LOAD_SUCCESS,
  BRANCH_LOAD_FAILURE
} from '../types/branch-types';
import { Branch } from '../../../domain/entities/branch-entity';

export interface IBranchState {
  loading: boolean;
  success: boolean;
  error: {
    message: string;
  } | null;
  branch: Branch | null;
}
const initialState: IBranchState = {
  loading: false,
  success: false,
  error: null,
  branch: null
};

export default function branch(state = initialState, action: any = null) {
  switch (action.type) {
    case BRANCH_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };

    case BRANCH_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      };

    case BRANCH_LOAD_SUCCESS:
      return {
        ...state,
        branch: action.payload,
        loading: false,
        success: true,
        error: null
      };

    default:
      return state;
  }
}
