import { Dispatch } from 'redux';
import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS
} from '../../../../../core/redux/types/loading-types';
import { ME_LOAD_SUCCESS } from '../../../../me/presentation/redux/types/me-types';
import { DeveloperRepositoryImpl } from '../../../data/repositories/developer-repository-impl';
import { DeveloperUsecaseImpl } from '../../../domain/usecases/developer-usecase';

import {
  APIKEY_LOAD_REQUEST,
  APIKEY_LOAD_SUCCESS,
  APIKEY_LOAD_FAILURE
} from '../types/apikey-types';

export const getApiKeyAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: APIKEY_LOAD_REQUEST });

    try {
      const developerRepository = new DeveloperRepositoryImpl();
      const developerUsecase = new DeveloperUsecaseImpl(developerRepository);

      const result = await developerUsecase.GetApiKey();

      dispatch({ type: APIKEY_LOAD_SUCCESS, payload: result.key });
    } catch (error) {
      dispatch({ type: APIKEY_LOAD_FAILURE, error });
    }
  };
};

export const updateWebhooksAction = (
  location_webhook: string,
  general_webhook: string,
  order_events: object,
  trip_events: object,
  task_events: object,
  driver_events: object,
  wallet_events: object,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const developerRepository = new DeveloperRepositoryImpl();
      const developerUsecase = new DeveloperUsecaseImpl(developerRepository);

      const result = await developerUsecase.UpdateWebhook(
        location_webhook,
        general_webhook,
        order_events,
        trip_events,
        task_events,
        driver_events,
        wallet_events
      );

      dispatch({ type: LOADING_SUCCESS });
      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
      props.handleClose();
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};
