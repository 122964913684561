// @ts-check
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  editCanRetryAbandonAction,
  editTripAppCreateAction
} from '../redux/actions/settings-actions';

import { Formik } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Radio, RadioGroup } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { editCanRetryAbandoned } from '../../data/datasources/edit-can-retry-abandoned';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function EditTripAppCreate(props) {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.loading);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && errors[name],
    helpertext: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Allow drivers to scan in tasks to create a trip</FormLabel>
        <RadioGroup aria-label="enabled" {...formikProps('enabled')}>
          <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
          <FormControlLabel value={'false'} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Save Changes
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

export function EditTripAppCreateFormComponent({
  can_create_trip,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  handleClose
}) {
  const dispatch = useDispatch();

  const values = {
    enabled: String(can_create_trip.enabled || false)
  };

  const submit = (data, actions) => {
    openBackdrop();
    data.enabled = data.enabled === 'true';

    const { resetForm } = actions;
    resetForm();
    const props = { closeBackdrop, showSnackbar, handleClose };
    dispatch(editTripAppCreateAction(data, props));
  };

  return (
    <Formik initialValues={values} onSubmit={submit}>
      {(props) => <EditTripAppCreate {...props} />}
    </Formik>
  );
}

function EditCanRetryAbandoned(props) {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.loading);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && errors[name],
    helpertext: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Allow drivers to retry abandoned tasks</FormLabel>
        <RadioGroup aria-label="enabled" {...formikProps('enabled')}>
          <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
          <FormControlLabel value={'false'} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Save Changes
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

export function EditCanRetryAbandonedFormComponent({
  can_retry_abandoned,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  handleClose
}) {
  const dispatch = useDispatch();

  const values = {
    enabled: String(can_retry_abandoned.enabled || false)
  };

  const submit = (data, actions) => {
    openBackdrop();
    data.enabled = data.enabled === 'true';

    const { resetForm } = actions;
    resetForm();
    const props = { closeBackdrop, showSnackbar, handleClose };
    dispatch(editCanRetryAbandonAction(data, props));
  };

  return (
    <Formik initialValues={values} onSubmit={submit}>
      {(props) => <EditCanRetryAbandoned {...props} />}
    </Formik>
  );
}
