// @ts-check
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { Divider } from '@material-ui/core';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import MapIcon from '@material-ui/icons/Map';
import GrainIcon from '@material-ui/icons/Grain';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { HomeMapComponent } from '../components/map-component';
import { getHubsAction } from '../../../hubs/presentation/redux/actions/hub-actions';
import { fetchAndActivate } from 'firebase/remote-config';
import { remoteConfig } from '../../../../index';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
    // border: `0.5px solid ${theme.palette.text.secondary}`
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  clientLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  icon: {
    fontSize: '4em'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

function Menu() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Paper className={classes.paper} elevation={0} onClick={() => history.push('/hubs')}>
          <GrainIcon className={classes.icon} fontSize="large" />

          <div>
            <b>Hubs</b>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper} elevation={0} onClick={() => history.push('/branches')}>
          <LocationOnIcon className={classes.icon} fontSize="large" />

          <div>
            <b>Branches</b>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper} elevation={0} onClick={() => history.push('/regions')}>
          <MapIcon className={classes.icon} fontSize="large" />

          <div>
            <b>Regions</b>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default function HomePage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHubsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchAndActivate(remoteConfig);
  }, []);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          <>
            <Menu />
            <Divider className={classes.divider} />

            <HomeMapComponent />
          </>
        </Container>
      </React.Fragment>
    </>
  );
}
