// @ts-check
import React from 'react';

import { useDispatch } from 'react-redux';
import { editClusterAction } from '../redux/actions/settings-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function EditClusterForm(props) {
  const classes = useStyles();

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isDisabled,
    isLogistics
  } = props;

  let isGroupMax = false;
  const type = isLogistics ? 'tasks' : 'orders';

  if (values.size === 1) {
    isGroupMax = true;
  }

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('distance')}
        id="outlined-full-width"
        label="Max travel distance"
        type="number"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: <InputAdornment position="end">km</InputAdornment>,
          inputProps: { min: 1 }
        }}
        inputProps={{
          'aria-label': 'distance'
        }}
        disabled={isDisabled}
      />
      <TextField
        {...formikProps('size')}
        id="outlined-full-width"
        label={`Max grouped ${type} size`}
        type="number"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          inputProps: { min: 1 }
        }}
        inputProps={{
          'aria-label': 'size'
        }}
        disabled={isDisabled}
      />

      <TextField
        {...formikProps('time')}
        id="outlined-full-width"
        label={`Clustering cut-off time after initial ${type.slice(0, -1)}`}
        type="number"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: <InputAdornment position="end">mins</InputAdornment>,
          inputProps: { min: 1 }
        }}
        inputProps={{
          'aria-label': 'time'
        }}
        disabled={isGroupMax}
      />

      <TextField
        {...formikProps('collection_time_range')}
        id="outlined-full-width"
        label={`Clustering collection time range after initial ${type.slice(0, -1)}`}
        type="number"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: <InputAdornment position="end">mins</InputAdornment>,
          inputProps: { min: 1 }
        }}
        inputProps={{
          'aria-label': 'collection_time_range'
        }}
        disabled={isGroupMax}
      />

      <div className={classes.formButtons}>
        {!isDisabled && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  distance: Yup.number('Enter maximum allowed travel distance')
    .required('Maximum allowed travel distance is required')
    .positive()
    .integer(),
  size: Yup.number('Enter maximum no. of orders that can be grouped together.')
    .required('No. of orders that can be grouped together name is required')
    .positive()
    .integer(),
  time: Yup.number('Enter the clustering algorithm cut-off time after the initial order')
    .required('Clustering cut-off time is required')
    .positive()
    .integer(),
  collection_time_range: Yup.number(
    'Enter the clustering algorithm collection time range after the initial order'
  )
    .required('Clustering collection time range is required')
    .positive()
    .integer()
});

export function EditClusterFormComponent({
  cluster,
  isDisabled,
  openBackdrop,
  closeBackdrop,
  isLogistics,
  showSnackbar,
  handleClose
}) {
  const dispatch = useDispatch();

  const values = {
    distance: cluster.distance,
    size: cluster.size,
    time: cluster.lock.time,
    collection_time_range: cluster.lock.collection_time_range
  };
  const submit = (data, actions) => {
    const { resetForm } = actions;
    openBackdrop();
    const props = { resetForm, closeBackdrop, showSnackbar, handleClose };
    dispatch(
      editClusterAction(data.distance, data.size, data.time, data.collection_time_range, props)
    );
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditClusterForm {...props} isDisabled={isDisabled} isLogistics={isLogistics} />}
    </Formik>
  );
}
