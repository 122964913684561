// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  contact: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

function BranchStoreCode({ branch }) {
  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemText
            primary={<small>Store code</small>}
            secondary={branch.store_code}
          ></ListItemText>
        </ListItem>
      </List>
    </>
  );
}

export function BranchStoreCodeComponent({ branch }) {
  const classes = useStyles();
  return (
    <div className={classes.contact}>
      <BranchStoreCode branch={branch} />
    </div>
  );
}
