// @ts-check
import React from 'react';

import { useDispatch } from 'react-redux';
import { editGeofenceAction } from '../redux/actions/settings-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, ListItemText, Box, Switch } from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  },
  switchButton: {
    left: '-10px',
    top: '-25px'
  },
  geofencingText: {
    float: 'left',
    position: 'absolute',
    left: '-20px'
  },
  textField: {
    left: '50px',
    top: '20px'
  }
}));

function EditGeofenceForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, isLogistics } = props;
  const type = isLogistics ? 'Completed' : 'Delivered';

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <RadioGroup aria-label="enabled" {...formikProps('enabled')}>
          <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
          <FormControlLabel value={'false'} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      <div>
        {!isLogistics && (
          <div>
            <ListItemText secondary="Drivers will be unable to move into an ‘Arrived at Branch’ status until they are within this distance range from the branch’s location." />
            <TextField
              {...formikProps('branch_distance')}
              id="outlined-full-width"
              label="Branch Distance"
              type="number"
              style={{ width: 300 }}
              variant="outlined"
              inputProps={{
                'aria-label': 'branch_distance'
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                inputProps: { min: 1 }
              }}
              className={classes.textField}
            />
            <Switch
              {...formikProps('branch_distance_enabled')}
              className={classes.switchButton}
              checked={values.branch_distance_enabled}
            />
          </div>
        )}
        <ListItemText secondary="Drivers will be unable to move into an ‘Arrived at Customer’ status until they are within this distance range from the customer’s location." />
        <TextField
          {...formikProps('customer_arrived_distance')}
          id="outlined-full-width"
          label="Customer Arrived Distance"
          type="number"
          style={{ width: 300 }}
          variant="outlined"
          inputProps={{
            'aria-label': 'customer_arrived_distance'
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
            inputProps: { min: 1 }
          }}
          className={classes.textField}
        />
        <Switch
          {...formikProps('customer_arrived_enabled')}
          className={classes.switchButton}
          checked={values.customer_arrived_enabled}
        />

        <ListItemText
          secondary={`Drivers will be unable to move into a '${type}' status until they are within this distance range from the customer’s location.`}
        />
        <TextField
          {...formikProps('customer_distance')}
          id="outlined-full-width"
          label="Customer Delivered Distance"
          type="number"
          style={{ width: 300 }}
          variant="outlined"
          inputProps={{
            'aria-label': 'customer_distance'
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
            inputProps: { min: 1 }
          }}
          className={classes.textField}
        />
        <Switch
          {...formikProps('customer_distance_enabled')}
          checked={values.customer_distance_enabled}
          className={classes.switchButton}
        />

        <ListItemText
          secondary={`Drivers will be unable to end a trip until they are within this distance range from the branch's location.`}
        />
        <TextField
          {...formikProps('end_trip_distance')}
          id="outlined-full-width"
          label="Driver End Trip Distance"
          type="number"
          style={{ width: 300 }}
          variant="outlined"
          inputProps={{
            'aria-label': 'end_trip_distance'
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
            inputProps: { min: 1 }
          }}
          className={classes.textField}
        />
        <Switch
          {...formikProps('end_trip_enabled')}
          checked={values.end_trip_enabled}
          className={classes.switchButton}
        />

      </div>
      <div className={classes.formButtons}>
        <>
          <Button
            className={classes.margin}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            Save Changes
          </Button>
        </>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  enabled: Yup.boolean('Select a setting').required('Enabled value setting is required'),
  branch_distance: Yup.number('Enter store distance').required('Store distance is required').min(0),
  customer_distance: Yup.number('Enter customer distance')
    .required('Customer distance is required')
    .min(0),
  customer_arrived_distance: Yup.number('Enter customer arrived distance')
    .required('Customer arrived distance is required')
    .min(0),
    end_trip_distance: Yup.number('Enter end trip distance')
    .required('End trip distance is required')
    .min(0)
});

export function EditGeofenceFormComponent({
  geofence,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  handleClose,
  isLogistics
}) {
  const dispatch = useDispatch();

  const values = {
    enabled: String(geofence.enabled),
    branch_distance: Number(geofence.branch_distance),
    customer_distance: Number(geofence.customer_distance),
    customer_arrived_distance: Number(geofence.customer_arrived_distance),
    branch_distance_enabled: Boolean(geofence.branch_distance_enabled),
    customer_distance_enabled: Boolean(geofence.customer_distance_enabled),
    customer_arrived_enabled: Boolean(geofence.customer_arrived_enabled),
    end_trip_distance: Number(geofence.end_trip_distance),
    end_trip_enabled: Boolean(geofence.end_trip_enabled),
  };

  const submit = (data, actions) => {
    data.enabled = data.enabled === 'true' ? true : false;
    openBackdrop();
    const { resetForm } = actions;
    const props = { resetForm, closeBackdrop, showSnackbar, handleClose };
    dispatch(editGeofenceAction(data, props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditGeofenceForm {...props} isLogistics={isLogistics} />}
    </Formik>
  );
}
