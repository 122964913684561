import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';

export const uploadUserImage = async (
  client_id: string,
  user_id: string,
  file: any
): Promise<any> => {
  try {
    const body = new FormData();
    body.append('file', file);
    body.append('client_id', client_id);
    body.append('user_id', user_id);

    const uri = `${config.uri.upload_url}/upload-user-image`;
    const options = {
      method: 'POST',
      headers: new Headers({
        // 'Access-Control-Allow-Origin': '*'
      }),
      body: body
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    throw err;
  }
};
