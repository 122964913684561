// @ts-check
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { editTerminateOrdersAction } from '../redux/actions/settings-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { Button, InputAdornment, ListItemText, TextField } from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function EditTerminateOrdersForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, isLogistics } = props;

  const type = isLogistics ? 'Tasks' : 'Orders';
  const [days, setDays] = useState(0);

  useEffect(() => {
    const hours = values.max_age ? values.max_age : 0;
    const days = +(hours / 24).toFixed(2);
    setDays(days);
  }, [values.max_age]);

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Terminate batched {type.toLowerCase()}</FormLabel>
        <RadioGroup aria-label="enabled" {...formikProps('enabled')}>
          <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
          <FormControlLabel value={'false'} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      {values.enabled === 'true' && (
        <div>
          <ListItemText
            secondary={`${type} older than the below number of hours will be terminated at 2AM.`}
          />
          <TextField
            {...formikProps('max_age', '24')}
            id="outlined-full-width"
            type="number"
            variant="outlined"
            fullWidth={true}
            disabled={values.enabled === 'false'}
            inputProps={{
              'aria-label': 'priority_distance'
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
              inputProps: { min: 24, max: 1460 }
            }}
          />
          <ListItemText secondary={days + ' day/s'} />
        </div>
      )}

      <div className={classes.formButtons}>
        <>
          <Button
            className={classes.margin}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            Save Changes
          </Button>
        </>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  enabled: Yup.boolean('Select a setting').required('Terminate orders setting is required'),
  max_age: Yup.number('Enter a number')
    .required('Order max age is required')
    .integer('Order max age has to be a whole number')
    .min(24, 'Order max age must be at least 24 hours')
    .max(1460, 'Order max age must be less than 1460 hours')
});

export function EditTerminateOrdersFormComponent({
  terminate_orders,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  handleClose,
  isLogistics
}) {
  const dispatch = useDispatch();

  const values = {
    enabled: String(terminate_orders.enabled),
    max_age: Number(terminate_orders.max_age)
  };

  const submit = (data, actions) => {
    data.enabled = data.enabled === 'true' ? true : false;

    openBackdrop();
    const { resetForm } = actions;
    const props = { resetForm, closeBackdrop, showSnackbar, handleClose };
    dispatch(editTerminateOrdersAction(data, props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditTerminateOrdersForm {...props} isLogistics={isLogistics} />}
    </Formik>
  );
}
