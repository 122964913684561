import React from 'react';

import Typography from '@material-ui/core/Typography/Typography';
import { useStyles } from './payment_providers.styles';
import { IBranch, PaymentProvider } from '../../../domain/entities/branch-entity';
import { EditPaymentFields } from './payment_fields_edit.component';

export default function PaymentProvidersComponent({ branch }: { branch: IBranch }) {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
        <small>
          <b>Payment Provider</b>
        </small>
      </Typography>
      {(branch.payment_fields ?? [PaymentProvider.empty()]).map((provider) => (
        <EditPaymentFields
          key={provider.acquirer_name ?? ''}
          branchId={branch.id}
          paymentProvider={provider}
        />
      ))}
    </div>
  );
}
