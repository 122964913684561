import { Me } from '../entities/me-entity';
import { MeRepository } from '../repositories/me-repository';

interface MeUsecase {
  GetMe(): Promise<Me>;
}

export class MeUsecaseImpl implements MeUsecase {
  meRepository: MeRepository;

  constructor(mr: MeRepository) {
    this.meRepository = mr;
  }

  GetMe(): Promise<Me> {
    return this.meRepository.GetMe();
  }
}
