import { Region } from '../../domain/entities/region-entity';
import { RegionRepository } from '../../domain/repositories/region-repository';
import { addHub } from '../datasources/add-hub';
import { createRegion } from '../datasources/create-region';
import { editRegion } from '../datasources/edit-region';
import { getRegion } from '../datasources/get-region';
import { getRegions } from '../datasources/get-regions';
import { removeHub } from '../datasources/remove-hub';

export class RegionRepositoryImpl implements RegionRepository {
  async CreateRegion(name: string): Promise<Region> {
    const result = await createRegion(name);

    return new Region(result, result.hubs);
  }

  async GetRegions(): Promise<Region[]> {
    const result = await getRegions();

    return result.map((region: any) => new Region(region));
  }

  async GetRegion(region_id: string): Promise<Region> {
    const result = await getRegion(region_id);

    return new Region(result, result.hubs);
  }

  async EditRegion(region_id: string, name: string): Promise<Region> {
    const result = await editRegion(region_id, name);

    return new Region(result, result.hubs);
  }

  async AddHub(region_id: string, hub_id: string): Promise<Region> {
    const result = await addHub(region_id, hub_id);

    return new Region(result, result.hubs);
  }

  async RemoveHub(region_id: string, hub_id: string): Promise<Region> {
    const result = await removeHub(region_id, hub_id);

    return new Region(result, result.hubs);
  }
}
