// @ts-check
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';

import Grid from '@material-ui/core/Grid';

import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';
import Divider from '@material-ui/core/Divider';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import MapIcon from '@material-ui/icons/Map';
import TimerIcon from '@material-ui/icons/Timer';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import { EditClusterFormComponent } from './edit-cluster-form-component';
import { ClientType } from '../../../../core/types/organization';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // maxWidth: 752
    margin: theme.spacing(2, 0)
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  form: {
    margin: theme.spacing(2, 2, 2, 2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function ClusterSettings({ cluster, openBackdrop, closeBackdrop, showSnackbar, isLogistics }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText primary="Cluster settings" secondary={null} />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Divider />

      {!open && (
        <div className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Max allowed travel distance"
                    secondary={`${cluster.distance} kilometers`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                      <MapIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Max grouped orders size"
                    secondary={`${cluster.size} orders`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                      <GroupWorkOutlinedIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Clustering cut-off time"
                    secondary={`${cluster.lock.time} minutes`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                      <TimerIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Clustering collection time range"
                    secondary={`${cluster.lock.collection_time_range} minutes`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                      <TimerIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      )}

      {open && (
        <>
          <div className={classes.form}>
            <EditClusterFormComponent
              isLogistics={isLogistics}
              cluster={cluster}
              isDisabled={!open}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}

      {/* <List>{render}</List> */}
    </div>
  );
}

export function ClusterSettingsComponent({ cluster, organization }) {
  const classes = useStyles();
  const isLogistics = organization.client_type === ClientType.LOGISTICS;

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Updated'
  });

  const showSnackbar = (status = 'success', description = 'Updated') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const openBackdrop = () => {
    setBackdrop(true);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <ClusterSettings
            cluster={cluster}
            isLogistics={isLogistics}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
      </Grid>

      <>
        <Backdrop
          className={classes.backdrop}
          open={backdrop}
          invisible={false}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </div>
  );
}
