import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';
import store from '../../../../core/redux/store';

export const createUser = async (
  firstname: string,
  lastname: string,
  email: string,
  mobile_no: string,
  password: string
): Promise<any> => {
  try {
    const state = store.getState();
    const token = state.token.token;

    const uri = `${config.uri.base_url}/users/create`;

    const options = {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ firstname, lastname, email, mobile_no, password })
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    throw err;
  }
};
