// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { ListItemSecondaryAction } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography/Typography';
import { EditBranchFormComponent } from './edit-branch-form-component';

import CallIcon from '@material-ui/icons/Call';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  contact: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

function Call({ branch }) {
  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemText
            primary={<small>Contact no.</small>}
            secondary={branch.contact}
          ></ListItemText>

          <ListItemSecondaryAction>
            <CallIcon />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
}

function BranchContactDetails({ branch }) {
  const classes = useStyles();

  return (
    <div className={classes.contact}>
      {/* <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
        <small>
          <b>Contact Details</b>
        </small>
      </Typography> */}

      <Call branch={branch} />
    </div>
  );
}

function BranchInformation({ branch }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemText primary={branch.name} secondary={null}></ListItemText>

          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {!open && <BranchContactDetails branch={branch} />}

      {open && (
        <>
          <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
            <small>
              <b>Edit Branch</b>
            </small>
          </Typography>
        </>
      )}

      {open && <EditBranchFormComponent branch={branch} handleClose={handleClose} />}
    </>
  );
}

export function BranchInformationComponent({ branch }) {
  return (
    <div>
      <BranchInformation branch={branch} />
    </div>
  );
}
