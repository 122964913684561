// @ts-check
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getHubsAction } from '../redux/actions/hub-actions';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { LoadingComponent } from '../components/loading-component.js';
import { FloatingActionButtonSize } from '../components/add-component';
import GrainIcon from '@material-ui/icons/Grain';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

function Hub({ hub }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Paper
      className={classes.paper}
      elevation={0}
      onClick={() => history.push(`/hubs/${hub.id}/view`)}
    >
      <GrainIcon fontSize="large" />

      <div>
        <b>{hub.name}</b>
      </div>
    </Paper>
  );
}

function HubsList({ hubs }) {
  const renderHubs = hubs.map((value, key) => {
    return (
      <Grid key={key} item xs={6} md={4}>
        <Hub hub={value} />
      </Grid>
    );
  });

  return (
    <Grid container spacing={2}>
      {renderHubs}
    </Grid>
  );
}

export default function HubsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { hubs, loading } = useSelector((state) => state.hubs);

  useEffect(() => {
    dispatch(getHubsAction());
  }, [dispatch]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && (
            <>
              <BackNavigationComponent
                page="Hubs"
                uri="/"
                path={[
                  {
                    page: 'Home',
                    uri: '/'
                  }
                ]}
              />
              <FloatingActionButtonSize />

              <HubsList hubs={hubs} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
