// @ts-check
import React from 'react';
import validator from 'validator';

import { useDispatch, useSelector } from 'react-redux';

import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { updateWebhooksAction } from '../redux/actions/developer-actions';
import {
  OrderEvents,
  TripEvents,
  TaskEvents,
  DriverAppEvents,
  WalletEvents
} from '../../../../core/middleware/events';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { ClientType } from '../../../../core/types/organization';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function UpdateWebhooksForm(props) {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.loading);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    handleClose,
    isLogistics
  } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  const webhookCheckbox = ({ field, form, label, ...rest }) => {
    const { name, value: formikValue } = field;
    const { setFieldValue } = form;

    const handleChange = () => {
      const values = formikValue || [];
      values[rest.value] = !values[rest.value];
      setFieldValue(name, values);
    };

    return (
      <label>
        <input
          type="checkbox"
          onChange={handleChange}
          checked={formikValue[rest.value]}
          {...rest}
        />
        <span>{label}</span>
      </label>
    );
  };

  // @ts-ignore
  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('location_webhook')}
        id="outlined-full-width"
        label="Location Webhook URL"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('general_webhook')}
        id="outlined-full-width"
        label="General Webhook URL"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <Grid container spacing={2}>
        {isLogistics ? (
          <Grid item xs={12} md={3}>
            <Typography variant="h6" component="div">
              Task events
            </Typography>
            <List>
              {Object.keys(TaskEvents).map((event, idx) => (
                <ListItem key={event}>
                  <Field
                    component={webhookCheckbox}
                    name="task_events"
                    value={event}
                    label={event}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ) : (
          <Grid item xs={12} md={3}>
            <Typography variant="h6" component="div">
              Order events
            </Typography>
            <List>
              {Object.keys(OrderEvents).map((event, idx) => (
                <ListItem key={event}>
                  <Field
                    component={webhookCheckbox}
                    name="order_events"
                    value={event}
                    label={event}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" component="div">
            Trip events
          </Typography>
          <List>
            {Object.keys(TripEvents).map((event, idx) => (
              <ListItem key={event}>
                <Field component={webhookCheckbox} name="trip_events" value={event} label={event} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="h6" component="div">
            Driver events
          </Typography>
          <List>
            {Object.keys(DriverAppEvents).map((event, idx) => (
              <ListItem key={event}>
                <Field
                  component={webhookCheckbox}
                  name="driver_events"
                  value={event}
                  label={event}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        {!isLogistics && (
          <Grid item xs={12} md={3}>
            <Typography variant="h6" component="div">
              Wallet events
            </Typography>
            <List>
              {Object.keys(WalletEvents).map((event, idx) => (
                <ListItem key={event}>
                  <Field
                    component={webhookCheckbox}
                    name="wallet_events"
                    value={event}
                    label={event}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Save Changes
            </Button>

            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={() => {
                window.location.reload();
              }}
            >
              Cancel
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  location_webhook: Yup.string('Enter location webhook url').test(
    'URL Test',
    'Enter a valid URL',
    (value) => {
      try {
        return validator.isURL(value);
      } catch (err) {
        return true;
      }
    }
  ),
  // .required('Location webhook url is required'),
  general_webhook: Yup.string('Enter general webhook url').test(
    'URL Test',
    'Enter a valid URL',
    (value) => {
      try {
        return validator.isURL(value);
      } catch (err) {
        return true;
      }
    }
  )
  // .required('General webhook url is required')
});

export function UpdateWebhooksFormComponent({ organization, handleClose, isLogistics }) {
  const dispatch = useDispatch();

  const values = {
    location_webhook: organization.webhooks.location,
    general_webhook: organization.webhooks.general,
    order_events: organization.webhooks.order_events,
    trip_events: organization.webhooks.trip_events,
    task_events: organization.webhooks.task_events,
    driver_events: organization.webhooks.driver_events,
    wallet_events: organization.webhooks.wallet_events
  };

  const submit = (data) => {
    const props = { handleClose };
    dispatch(
      updateWebhooksAction(
        data.location_webhook,
        data.general_webhook,
        data.order_events,
        data.trip_events,
        data.task_events,
        data.driver_events,
        data.wallet_events,
        props
      )
    );
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => (
        <UpdateWebhooksForm {...props} handleClose={handleClose} isLogistics={isLogistics} />
      )}
    </Formik>
  );
}
