// @ts-check
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { editRouteOptimisationAction } from '../redux/actions/settings-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function EditRouteOptimisationForm(props) {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.loading);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, isLogistics } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Select a route optimisation algorithm</FormLabel>
        <RadioGroup aria-label="priority" {...formikProps('priority')}>
          <FormControlLabel value="Default" control={<Radio />} label="Default" />
          <FormControlLabel
            value="Service Level Agreement"
            control={<Radio />}
            label="Service Level Agreement"
          />
          {isLogistics && (
            <div>
              <FormControlLabel
                value="Collection"
                control={<Radio />}
                label="Prioritise collections"
              />
              <FormControlLabel value="Delivery" control={<Radio />} label="Prioritise deliveries" />
            </div>
          )}
        </RadioGroup>
      </FormControl>

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Save Changes
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  priority: Yup.string('Select a dispatching algorithm').required(
    'Dispatching algorithm is required'
  )
});

export function EditRouteOptimisationFormComponent({
  route_optimisation,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  handleClose,
  isLogistics
}) {
  const dispatch = useDispatch();

  const values = { priority: route_optimisation.priority };

  const submit = (data, actions) => {
    openBackdrop();

    let value;

    if (data.length === 0) {
      value = {
        priority: 'Default'
      };
    } else {
      value = {
        priority: data.priority
      };
    }

    const { resetForm } = actions;
    const props = { resetForm, closeBackdrop, showSnackbar, handleClose };
    dispatch(editRouteOptimisationAction(value, props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditRouteOptimisationForm {...props} isLogistics={isLogistics} />}
    </Formik>
  );
}
