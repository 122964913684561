// @ts-check
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import GrainIcon from '@material-ui/icons/Grain';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  icon: {
    fontSize: '8em'
  }
}));

export function HubMapComponent({ hub }) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <GrainIcon className={classes.icon} fontSize="large" />

        <div>
          <b>{hub.name}</b>
        </div>
      </Paper>
    </>
  );
}
