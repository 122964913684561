import { useStyles } from './payment_providers.styles';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import React from 'react';

import { IBranchState } from '../../redux/reducers/branch-reducer';

export default function PaymentFieldsForm({
  errors,
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
  ...rest
}) {
  const classes = useStyles();

  const { error, loading } = useSelector((state) => state.branch as IBranchState);
  const formikProps = (name: string) => ({
    name,
    value: rest.values[name],
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('merchant_id')}
        id="outlined-full-width"
        label="Merchant ID"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('acquirer_id')}
        id="outlined-full-width"
        label="Acquirer ID"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <Button
            className={classes.margin}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            Submit
          </Button>
        )}

        {error && (
          <div data-testid="FORM_FIELDS_ERROR_MESSAGE">
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}
