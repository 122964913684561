// @ts-check
import React from 'react';

import { useDispatch } from 'react-redux';
import { editRatingAction } from '../redux/actions/settings-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function AddRatingForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('rating')}
        id="outlined-full-width"
        label="Name"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <div className={classes.formButtons}>
        <>
          <Button
            className={classes.margin}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            Add Rating
          </Button>
        </>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  rating: Yup.string('Enter rating name').required('Rating name is required')
});

export function AddRatingFormComponent({ ratings, openBackdrop, closeBackdrop, showSnackbar }) {
  const dispatch = useDispatch();

  const values = { rating: '' };
  const submit = (data, actions) => {
    if (ratings.length >= 4) {
      closeBackdrop();
      showSnackbar('error', 'Only 4 ratings are allowed');
      return;
    }

    const { resetForm } = actions;
    openBackdrop();
    const props = { resetForm, closeBackdrop, showSnackbar };
    dispatch(editRatingAction([...ratings, data.rating], props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <AddRatingForm {...props} />}
    </Formik>
  );
}
