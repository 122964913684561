// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import LockIcon from '@material-ui/icons/Lock';
import { ListItemSecondaryAction } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { EditUserPasswordFormComponent } from './edit-user-password-form-component';
import Typography from '@material-ui/core/Typography/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

function UserPassword({ user }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <LockIcon />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
              </span>
            }
            secondary={null}
          ></ListItemText>

          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
            <small>
              <b>Change Password</b>
            </small>
          </Typography>
        </>
      )}

      {open && <EditUserPasswordFormComponent user={user} handleClose={handleClose} />}
    </>
  );
}

export function UserPasswordComponent({ user }) {
  return (
    <div>
      <UserPassword user={user} />
    </div>
  );
}
