import { Dispatch } from 'redux';
import { REMOVE_USER_TOKEN } from '../types/token-types';

export const logoutAction = () => {
  return async (dispatch: Dispatch) => {
    localStorage.removeItem('cowabunga-client-admin');

    dispatch({ type: REMOVE_USER_TOKEN });
    dispatch({ type: 'RESET_APPLICATION_STATE' });
  };
};
