// @ts-check
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, ListItemSecondaryAction } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider/Divider';

import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { removeHubFromRegionAction } from '../redux/actions/hub-actions';
import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1)
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  hubLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: 100000000000,
    color: '#fff'
  }
}));

function AssociatedRegions({ hub_id, regions }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Region removed from hub'
  });

  const showSnackbar = (status = 'success', description = 'Region removed from hub') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const removeHubFromRegion = (region_id) => {
    setBackdrop(true);

    const props = { closeBackdrop, showSnackbar };
    dispatch(removeHubFromRegionAction(region_id, hub_id, props));
  };

  const n = regions.length - 1;
  const parties = regions.map((region, index) => {
    return (
      <div key={index}>
        <ListItem button onClick={() => history.push(`/regions/${region.id}/view`)}>
          <ListItemText primary={`${region.name}`} secondary={null}></ListItemText>

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={() => removeHubFromRegion(region.id)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {index !== n && <Divider />}
      </div>
    );
  });

  return (
    <div className={classes.root}>
      {regions.length === 0 && <small>no regions</small>}
      {regions.length > 0 && <List className={classes.root}>{parties}</List>}

      <>
        <Backdrop
          className={classes.backdrop}
          open={backdrop}
          invisible={false}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </div>
  );
}

export function AssociatedRegionsComponent({ hub }) {
  const classes = useStyles();

  return (
    <div className={classes.margin}>
      <>
        <AssociatedRegions hub_id={hub.id} regions={hub.regions} />
      </>
    </div>
  );
}
