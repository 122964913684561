import { Me } from '../../../me/domain/entities/me-entity';
import { ApiKey } from '../entities/api-key-entity';
import { DeveloperRepository } from '../repositories/developer-repository';

interface DeveloperUsecase {
  GetApiKey(): Promise<ApiKey>;
  UpdateWebhook(
    location_webhook: string,
    general_webhook: string,
    order_events: object,
    trip_events: object,
    task_events: object,
    driver_events: object,
    wallet_events: object
  ): Promise<Me>;
}

export class DeveloperUsecaseImpl implements DeveloperUsecase {
  developerRepository: DeveloperRepository;

  constructor(cr: DeveloperRepository) {
    this.developerRepository = cr;
  }

  UpdateWebhook(
    location_webhook: string,
    general_webhook: string,
    order_events: object,
    trip_events: object,
    task_events: object,
    driver_events: object,
    wallet_events: object
  ): Promise<Me> {
    return this.developerRepository.UpdateWebhook(
      location_webhook,
      general_webhook,
      order_events,
      trip_events,
      task_events,
      driver_events,
      wallet_events
    );
  }

  GetApiKey(): Promise<ApiKey> {
    return this.developerRepository.GetApiKey();
  }
}
