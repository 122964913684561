import { Dispatch } from 'redux';

import { SettingsRepositoryImpl } from '../../../data/repositories/settings-repository-impl';
import { SettingsUsecaseImpl } from '../../../domain/usecases/settings-usecase';

import {
  SETTINGS_LOAD_REQUEST,
  SETTINGS_LOAD_SUCCESS,
  SETTINGS_LOAD_FAILURE,
  TerminateOrders,
  OrderHistory,
  AutomaticReturns
} from '../types/settings-types';

export const getSettingsAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SETTINGS_LOAD_REQUEST });

    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.GetSettings();
      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: SETTINGS_LOAD_FAILURE, error });
    }
  };
};

export const editPoolingAction = (algorithm: string, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditPooling(algorithm);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.resetForm();
      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Dispatching algorithm updated');
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editRouteOptimisationAction = (route_optimisation: object, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditRouteOptimisation(route_optimisation);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.resetForm();
      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Route Optimisation settings updated');
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editClusterAction = (
  distance: number,
  size: number,
  lock_time: number,
  collection_time_range: number,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditCluster(
        distance,
        size,
        'default',
        lock_time,
        collection_time_range
      );

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Clustering settings updated');
      props.resetForm();
      props.handleClose();
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editRatingAction = (ratings: string[], props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditRatings(ratings);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Ratings list updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editAbandonmentReasonAction = (reasons: string[], props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditAbandonmentReasons(reasons);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Abandonment reason list updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editPartialDeliveryReasonAction = (reasons: string[], props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditPartialDeliveryReasons(reasons);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Partial Delivery reason list updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editTaskPauseReasonsAction = (reasons: string[], props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditTaskPauseReasons(reasons);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Task pause reason list updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editSmsAction = (sms_enabled: boolean, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditSms(sms_enabled);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Tracking page settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editVehicleTypesAction = (types: string[], props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditVehicleTypes(types);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.showSnackbar('success', 'Vehicle type list updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editTerminateOrdersAction = (terminate_orders: TerminateOrders, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditTerminateOrders(terminate_orders);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Terminate orders settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editQRTypingAction = (terminate_orders: boolean, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditQRTyping(terminate_orders);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'QR typing settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editGeofenceAction = (geofence: object, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditGeofence(geofence);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Geofence settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editPoolingGeofenceAction = (geofence: object, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditPoolingGeofence(geofence);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Driver pooling geofence settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editRecipientNameAction = (recipient_name: object, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);
      const result = await settingsUsecase.EditRecipientName(recipient_name);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Recipient Name settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editTripSequencing = (trip_sequencing: object, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);
      const result = await settingsUsecase.EditTripSequencing(trip_sequencing);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Driver Trip Re-Sequencing settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editOrderHistoryAction = (order_history: OrderHistory, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditOrderHistory(order_history);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Order History settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editTripAppCreateAction = (can_create_trip: object, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);
      const result = await settingsUsecase.EditTripAppCreate(can_create_trip);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Driver can create trip settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editCanRetryAbandonAction = (can_retry_abandoned: object, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);
      const result = await settingsUsecase.EditCanRetryAbandoned(can_retry_abandoned);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar('success', 'Driver can create trip settings updated');
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};

export const editAutomaticReturnsAction = (automatic_returns: AutomaticReturns, props: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const settingsRepository = new SettingsRepositoryImpl();
      const settingsUsecase = new SettingsUsecaseImpl(settingsRepository);

      const result = await settingsUsecase.EditAutomaticReturns(automatic_returns);

      dispatch({ type: SETTINGS_LOAD_SUCCESS, payload: result });

      props.closeBackdrop();
      props.handleClose();
      props.showSnackbar(
        'success',
        'Automatically mark abandoned collections as returned setting updated'
      );
      if (props.resetForm) {
        props.resetForm();
      }
    } catch (error) {
      if (error instanceof Error) {
        props.closeBackdrop();
        props.showSnackbar('error', error.message);
      } else {
        console.log('Unexpected error', error);
      }
    }
  };
};
