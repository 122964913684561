// @ts-check
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress, Typography } from '@material-ui/core';

import { findOrganizationAction } from '../redux/actions/organization-action';
import { fetchAndActivate } from 'firebase/remote-config';
import { remoteConfig } from '../../../../index';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  logo: {
    height: '3rem'
  },
  logoContainer: {
    textAlign: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(10)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function OrganizationForm(props) {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.organization);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('organization')}
        id="outlined-full-width"
        label="Organization"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
            // disabled={!isValid}
          >
            Continue
          </Button>
        )}

        {loading && (
          <Button variant="contained" size="large" color="primary" fullWidth={true}>
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  organization: Yup.string('Enter your organization name')
    .trim()
    .required('Organization name is required')
});

function OrganizationFormValidation() {
  const dispatch = useDispatch();

  const values = { organization: '' };

  const submit = async (data, actions) => {
    await dispatch(findOrganizationAction(data.organization));

    // actions.resetForm();
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <OrganizationForm {...props} />}
    </Formik>
  );
}

export function FindOrganizationPage() {
  const classes = useStyles();

  useEffect(() => {
    fetchAndActivate(remoteConfig);
  }, []);

  return (
    <React.Fragment>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src="loop-logo.png" alt="logo" />
      </div>

      <Typography variant="subtitle1" component="h3" align="center">
        <b>Enter your organization's unique name</b>
      </Typography>

      <OrganizationFormValidation />
    </React.Fragment>
  );
}
