import React, { useEffect, useState } from 'react';
import { isValidNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { createBranchAction } from '../redux/actions/branch-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  ListItemSecondaryAction,
  MenuItem
} from '@material-ui/core';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';
import { CreateBranchAddressComponent } from '../components/create-branch-address-component';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { getHubsAction } from '../../../hubs/presentation/redux/actions/hub-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  branch: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2)
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function CreateBranchForm(props) {
  const classes = useStyles();
  const history = useHistory();

  const { hubs } = useSelector((state) => state.hubs);
  const { error, loading } = useSelector((state) => state.branch);

  const hubs_list = hubs.map((hub) => ({ value: hub.id, label: hub.name }));

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('name')}
        id="outlined-full-width"
        label="Name"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('contact')}
        id="outlined-full-width"
        label="Contact no."
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('store_code')}
        id="outlined-full-width"
        label="Store code"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('dashboard_url')}
        id="outlined-full-width"
        label="Dashboard url"
        type="text"
        variant="outlined"
        fullWidth={true}
      />
      <TextField
        {...formikProps('hub')}
        id="outlined-select-currency"
        select
        label="Hub"
        variant="outlined"
        fullWidth={true}
      >
        {hubs_list.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Continue
            </Button>

            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={() => history.push('/branches')}
            >
              Cancel
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  name: Yup.string('Enter branch name').required('Branch name is required'),
  contact: Yup.string('Enter branch contact no.')
    .test(
      'Phone Test',
      'Enter a valid phone no., with a valid iso code. (e.g +9723656759)',
      (value) => {
        try {
          return isValidNumber(value) && validatePhoneNumberLength(value) === undefined;
        } catch (err) {
          return true;
        }
      }
    )
    .required('Contact no. is required'),
  hub: Yup.string('Select a hub').required('Hub is required')
});

function CreateBranchFormValidation({ submitDetails }) {
  const values = {
    name: '',
    contact: '',
    store_code: '',
    dashboard_url: '',
    hub: ''
  };

  const submit = (data) => {
    submitDetails(data);
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <CreateBranchForm {...props} />}
    </Formik>
  );
}

function BranchDetails({ branch }) {
  const classes = useStyles();

  return (
    <div>
      <List className={classes.branch}>
        <ListItem>
          <ListItemText primary={branch.name} secondary={null}></ListItemText>

          <ListItemSecondaryAction>
            <LocationOnIcon />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}

export default function CreateBranchPage() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [view, setView] = useState(false);
  const [branch, setBranch] = useState({});

  useEffect(() => {
    dispatch(getHubsAction());
  }, [dispatch]);

  const submitDetails = (data) => {
    setBranch(data);
    setView(true);
  };

  const submit = (data) => {
    if (data.valid) {
      const props = { history };
      // @ts-ignore
      const params = {
        // @ts-ignore
        hub_id: branch.hub,
        // @ts-ignore
        name: branch.name,
        // @ts-ignore
        contact: branch.contact,
        // @ts-ignore
        store_code: branch.store_code,
        // @ts-ignore
        dashboard_url: branch.dashboard_url,
        address: data.formatted_address,
        location: data.location
      };
      dispatch(
        createBranchAction(
          params.hub_id,
          params.name,
          params.contact,
          params.store_code,
          params.dashboard_url,
          params.address,
          params.location,
          props
        )
      );
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.container}>
      <BackNavigationComponent
              page="Create branch"
              uri="/branches"
              path={[
                {
                  page: 'Home',
                  uri: '/'
                },
                {
                  page: 'Branches',
                  uri: '/branches'
                }
              ]}
            />
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={8}>
    

            {!view && <CreateBranchFormValidation submitDetails={submitDetails} />}
            {view && <BranchDetails branch={branch} />}
            {view && <CreateBranchAddressComponent submit={submit} />}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
