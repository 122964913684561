// @ts-check
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoginPage from '../../../features/login/presentation/pages/login-page';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getMeAction } from '../../../features/me/presentation/redux/actions/me-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

export function AccessComponent(props) {
  const classes = useStyles();
  const { token } = useSelector((state) => state.token);
  const { loading, success } = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const bearer = localStorage.getItem('cowabunga-client-admin');

  useEffect(() => {
    if (bearer) {
      dispatch(getMeAction(bearer));
    }
  }, [dispatch, bearer]);

  useEffect(() => {}, [token]);

  if (!bearer) {
    return <LoginPage />;
  }

  if (bearer && loading && !success) {
    return (
      <div className={classes.root}>
        <CircularProgress />
        <br />
        <br />
        <i>loading...</i>
      </div>
    );
  }

  return props.children;
}
