// @ts-check
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Box, IconButton, ListItemSecondaryAction } from '@material-ui/core';

import Divider from '@material-ui/core/Divider/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography/Typography';

import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { revokeHubAccessAction } from '../redux/actions/user-actions';
import { GrantHubAccessFormComponent } from './grant-hub-access-form-component';
import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(0)
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    marginBottom: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  userLogo: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function HubsInRegion({ user_id, hubs }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Hub permission revoked'
  });

  const showSnackbar = (status = 'success', description = 'Hub permission revoked') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const removeHub = (hub_id) => {
    setBackdrop(true);
    const props = { closeBackdrop, showSnackbar };
    dispatch(revokeHubAccessAction(user_id, hub_id, props));
  };

  const n = hubs.length - 1;
  const parties = hubs.map((hub, index) => {
    return (
      <div key={index}>
        <ListItem button>
          <ListItemText primary={`${hub.name}`} secondary={null}></ListItemText>

          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="comments" onClick={() => removeHub(hub.id)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {index !== n && <Divider />}
      </div>
    );
  });

  return (
    <div className={classes.root}>
      {hubs.length === 0 && <small>no hubs permissions </small>}
      {hubs.length > 0 && (
        <List className={classes.root}>
          <Divider />
          {parties}
        </List>
      )}

      <>
        <Backdrop
          className={classes.backdrop}
          open={backdrop}
          invisible={false}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </div>
  );
}

export function HubsPermissionsComponent({ user }) {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState(false);

  const showSnackbar = () => {
    setSnackbar(true);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  return (
    <div className={classes.margin}>
      <>
        <Box p={2}>
          <GrantHubAccessFormComponent user={user} showSnackbar={showSnackbar} />
        </Box>

        <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
          <small>
            <b>Hubs Granted</b>
          </small>
        </Typography>

        <HubsInRegion user_id={user.id} hubs={user.permissions.dos.hubs} />

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={'success'}
          message={'User granted hub permission'}
        />
      </>
    </div>
  );
}
