import { Me } from '../../domain/entities/me-entity';
import { MeRepository } from '../../domain/repositories/me-repository';
import { getMe } from '../datasources/get-me';

export class MeRepositoryImpl implements MeRepository {
  async GetMe(): Promise<Me> {
    const result = await getMe();

    return new Me(result);
  }
}
