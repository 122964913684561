// @ts-check
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getUserAction } from '../redux/actions/user-actions';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { Paper } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import { LoadingComponent } from '../components/loading-component';
import { UploadUserImageComponent } from '../components/upload-logo-component';
import { UserInformationComponent } from '../components/user-information-component';
import { UserDetailsComponent } from '../components/user-contact-details-component';
import { UserPasswordComponent } from '../components/user-password-component';

import { getHubsAction } from '../../../hubs/presentation/redux/actions/hub-actions';
import { getRegionsAction } from '../../../regions/presentation/redux/actions/region-actions';

import { DOSPermissionsComponent } from '../components/dos-permissions-component';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';
import { GeneralPermissionsComponent } from '../components/general-permissions-component';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(3)
  },
  userHeader: {
    textAlign: 'center'
  },
  userLogo: {
    height: '10rem',
    width: '10rem',
    objectFit: 'contain'
  },
  large: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}));

function Client({ user }) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <UploadUserImageComponent user={user} />
      </Paper>
    </>
  );
}

function CenteredGrid({ user }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Client user={user} />
      </Grid>

      <Grid item xs={12} md={8}>
        <UserInformationComponent user={user} />
        <UserPasswordComponent user={user} />
        <UserDetailsComponent user={user} />
        <GeneralPermissionsComponent user={user} />
        <DOSPermissionsComponent user={user} />
      </Grid>
    </Grid>
  );
}

export default function UserPage() {
  // @ts-ignore
  const { id } = useParams();
  const classes = useStyles();

  const { user, loading, success } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserAction(id));
    dispatch(getHubsAction());
    dispatch(getRegionsAction());
  }, [dispatch, id]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="User"
                uri="/users"
                path={[
                  {
                    page: 'Home',
                    uri: '/'
                  },
                  {
                    page: 'Users',
                    uri: '/users'
                  }
                ]}
              />
              <CenteredGrid user={user} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
