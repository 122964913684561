import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';
import store from '../../../../core/redux/store';

export const editCluster = async (
  distance: number,
  size: number,
  lock_strategy: string,
  lock_time: number,
  collection_time_range: number
): Promise<any> => {
  try {
    const state = store.getState();
    const token = state.token.token;

    const uri = `${config.uri.base_url}/settings/edit/cluster`;

    const options = {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ distance, size, lock_strategy, lock_time, collection_time_range })
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    throw err;
  }
};
