import { Branch } from '../entities/branch-entity';
import { BranchRepository } from '../repositories/branch-repository';

interface BranchUsecase {
  CreateBranch(
    hub_id: string,
    name: string,
    contact: string,
    store_code: string,
    dashboard_url: string,
    address: string,
    location: any
  ): Promise<Branch>;
  EditBranch(
    branch_id: string,
    name: string,
    store_code: string,
    dashboard_url: string,
    contact: string
  ): Promise<Branch>;
  EditAddress(branch_id: string, address: string, location: any): Promise<Branch>;
  GetBranch(branch_id: string): Promise<Branch>;
  GetBranches(): Promise<Branch[]>;
  ChangeHub(branch_id: string, hub_id: string): Promise<Branch>;
  EditPaymentFields(
    branch_id: string,
    merchant_id: string,
    acquirer_id: string,
    acquirer_name: string
  );
  EditBranchThreePl(branch_id: string, partner_id: string, name: string);
  DeleteBranchThreePl(branch_id: string);
}

export class BranchUsecaseImpl implements BranchUsecase {
  branchRepository: BranchRepository;

  constructor(br: BranchRepository) {
    this.branchRepository = br;
  }

  CreateBranch(
    hub_id: string,
    name: string,
    contact: string,
    store_code: string,
    dashboard_url: string,
    address: string,
    location: any
  ): Promise<Branch> {
    return this.branchRepository.CreateBranch(
      hub_id,
      name,
      contact,
      store_code,
      dashboard_url,
      address,
      location
    );
  }

  EditBranch(
    branch_id: string,
    name: string,
    store_code: string,
    dashboard_url: string,
    contact: string
  ): Promise<Branch> {
    return this.branchRepository.EditBranch(branch_id, name, store_code, dashboard_url, contact);
  }

  EditAddress(branch_id: string, address: string, location: any): Promise<Branch> {
    return this.branchRepository.EditAddress(branch_id, address, location);
  }

  GetBranch(branch_id: string): Promise<Branch> {
    return this.branchRepository.GetBranch(branch_id);
  }

  GetBranches(): Promise<Branch[]> {
    return this.branchRepository.GetBranches();
  }

  ChangeHub(branch_id: string, hub_id: string): Promise<Branch> {
    return this.branchRepository.ChangeHub(branch_id, hub_id);
  }
  EditPaymentFields(
    branch_id: string,
    merchant_id: string,
    acquirer_id: string,
    acquirer_name: string
  ): Promise<Branch> {
    return this.branchRepository.EditPaymentFields(
      branch_id,
      merchant_id,
      acquirer_id,
      acquirer_name
    );
  }

  EditBranchThreePl(branch_id: string, partner_id: string) {
    return this.branchRepository.EditBranchThreePl(branch_id, partner_id);
  }
  DeleteBranchThreePl(branch_id: string) {
    return this.branchRepository.DeleteBranchThreePl(branch_id);
  }
}
