// @ts-check
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { grantHubAccessAction } from '../redux/actions/user-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0)
  },
  formButtons: {
    marginTop: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function GrantHubAccessForm(props) {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.loading);
  const { hubs } = useSelector((state) => state.hubs);

  const hubs_list = hubs.map((hub) => ({ value: hub.id, label: hub.name }));

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('hub')}
        id="outlined-select-currency"
        select
        label="Hub"
        variant="outlined"
        fullWidth={true}
      >
        {hubs_list.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Grant Hub Access
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  hub: Yup.string('Select a hub').required('Hub is required')
});

export function GrantHubAccessFormComponent({ user, showSnackbar }) {
  const dispatch = useDispatch();

  const values = { hub: '' };

  const submit = (data) => {
    const props = { showSnackbar };
    dispatch(grantHubAccessAction(user.id, data.hub, props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <GrantHubAccessForm {...props} />}
    </Formik>
  );
}
