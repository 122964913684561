const APP_ENV = process.env.REACT_APP_ENV;

let envConfig;

const dev = {
  auth_url: `https://cb-authentication-api-ukxjb66ceq-ew.a.run.app`,
  base_url: `https://cb-client-admin-api-ukxjb66ceq-ew.a.run.app`,
  upload_url: `https://cb-image-upload-api-ukxjb66ceq-ew.a.run.app`,

  dos_app: `https://new-dos-dev-update-dot-cowabunga-home.ue.r.appspot.com`,
  client_admin_app: `https://cb-client-admin-ukxjb66ceq-ew.a.run.app`,
  fleet_management_app: `https://cb-fleet-management-ukxjb66ceq-ew.a.run.app`,
  api_documentation: `https://documenter.getpostman.com/view/11385823/TW77i4cH`
};

const uat = {
  auth_url: `https://cb-authentication-api-q3xi6azozq-ew.a.run.app`,
  base_url: `https://cb-client-admin-api-q3xi6azozq-ew.a.run.app`,
  upload_url: `https://cb-image-upload-api-q3xi6azozq-ew.a.run.app`,
  dos_app: `https://cowabunga-home.ue.r.appspot.com`,
  client_admin_app: `https://cb-client-admin-q3xi6azozq-ew.a.run.app`,
  fleet_management_app: `https://cb-fleet-management-q3xi6azozq-ew.a.run.app`,
  api_documentation: `https://documenter.getpostman.com/view/11385823/TW77i4cH`
};

const prod = {
  auth_url: `https://cb-authentication-api-f5dovyimaq-ew.a.run.app`,
  base_url: `https://cb-client-admin-api-f5dovyimaq-ew.a.run.app`,
  upload_url: `https://cb-image-upload-api-f5dovyimaq-ew.a.run.app`,
  dos_app: `https://cowabunga-home.ue.r.appspot.com`,
  client_admin_app: `https://client.cowabunga.co.za`,
  fleet_management_app: `https://fleet.cowabunga.co.za`,
  api_documentation: `https://documenter.getpostman.com/view/11385823/TW77i4cH`
};

const app_images = {
  default_logo: `https://storage.googleapis.com/cb-dev-public-images/client-logos/f9cd9918-4d1e-11ec-b4c4-bda07fef6bb8-loop_black.png`,
  app_fleet_icon: `https://storage.googleapis.com/cowabunga-public-images/asserts/Fleet-Icon.PNG`,
  app_admin_icon: `https://storage.googleapis.com/cowabunga-public-images/asserts/Admin-Icon.PNG`,
  app_dos_icon: `https://storage.googleapis.com/cowabunga-public-images/asserts/DOS-Icon.PNG`
};

switch (APP_ENV) {
  case 'production':
    envConfig = prod;
    break;
  case 'uat':
    envConfig = uat;
    break;
  case 'development':
  default:
    envConfig = dev;
    console.log(`environment does not exist, check yaml files.`);
}

export const config = {
  uri: envConfig,
  images: app_images
};
