// @ts-check
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import NavBarComponent from './components/navbar-component';
import { AccessComponent } from './components/access-component';

import UsersPage from '../../features/users/presentation/pages/users-page';
import UserPage from '../../features/users/presentation/pages/user-page';
import CreateUserPage from '../../features/users/presentation/pages/create-user-page';

import { ProfilePage } from '../../features/me/presentation/pages/profile-page';

import HomePage from '../../features/home/presentation/pages/home-page';

import HubsPage from '../../features/hubs/presentation/pages/hubs-page';
import HubPage from '../../features/hubs/presentation/pages/hub-page';
import CreateHubPage from '../../features/hubs/presentation/pages/create-hub-page';

import BranchesPage from '../../features/branches/presentation/pages/branches-page';
import BranchPage from '../../features/branches/presentation/pages/branch-page';
import CreateBranchPage from '../../features/branches/presentation/pages/create-branch-page';

import RegionsPage from '../../features/regions/presentation/pages/regions-page';
import RegionPage from '../../features/regions/presentation/pages/region-page';
import CreateRegionPage from '../../features/regions/presentation/pages/create-region-page';

import DeveloperPage from '../../features/developer/presentation/pages/developer-page';
import { PageNotFoundComponent } from './components/page-not-found-component';
import SettingsPage from '../../features/settings/presentation/pages/settings-page';

const routes = () => {
  return (
    <Router>
      <AccessComponent>
        <NavBarComponent>
          <Switch>
            <Route exact path="/" component={HomePage} />

            <Route path="/account" component={ProfilePage} />

            <Route exact path="/hubs" component={HubsPage} />
            <Route path="/hubs/create" component={CreateHubPage} />
            <Route path="/hubs/:id/view" component={HubPage} />

            <Route exact path="/branches" component={BranchesPage} />
            <Route path="/branches/create" component={CreateBranchPage} />
            <Route path="/branches/:id/view" component={BranchPage} />

            <Route exact path="/regions" component={RegionsPage} />
            <Route path="/regions/create" component={CreateRegionPage} />
            <Route path="/regions/:id/view" component={RegionPage} />

            <Route exact path="/users" component={UsersPage} />
            <Route path="/users/create" component={CreateUserPage} />
            <Route path="/users/:id/view" component={UserPage} />

            <Route exact path="/settings" component={SettingsPage} />

            <Route exact path="/developer" component={DeveloperPage} />
            <Route component={PageNotFoundComponent} />
          </Switch>
        </NavBarComponent>
      </AccessComponent>
    </Router>
  );
};

export default routes;
