import { HUB_LOAD_REQUEST, HUB_LOAD_SUCCESS, HUB_LOAD_FAILURE } from '../types/hub-types';

const initialState = {
  loading: false,
  success: false,
  error: null,
  hub: null
};

function hub(state = initialState, action: any = null) {
  switch (action.type) {
    case HUB_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };

    case HUB_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      };

    case HUB_LOAD_SUCCESS:
      return {
        ...state,
        hub: action.payload,
        loading: false,
        success: true,
        error: null
      };

    default:
      return state;
  }
}

export default hub;
