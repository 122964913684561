// @ts-check
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import { AddVehicleTypesFormComponent } from './add-vehicle-types-form-component';

import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';
import Divider from '@material-ui/core/Divider';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { editVehicleTypesAction } from '../redux/actions/settings-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // maxWidth: 752
    margin: theme.spacing(0, 0, 2, 0)
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  form: {
    margin: theme.spacing(2, 2, 2, 2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function VehicleTypes({ types, openBackdrop, closeBackdrop, showSnackbar }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const remove = (type) => {
    const _types = [...types];

    const index = _types.indexOf(type);

    if (['Bike', 'Car', 'Bakkie', 'E-bike'].includes(type)) {
      openBackdrop();
      const props = { closeBackdrop, showSnackbar };
      showSnackbar('error', type + ' cannot be removed');
      return;
    }

    if (index > -1) {
      openBackdrop();
      const props = { closeBackdrop, showSnackbar };
      _types.splice(index, 1);

      dispatch(editVehicleTypesAction(_types, props));
    }
  };

  const render = types.map((type, key) => (
    <ListItem key={key}>
      <ListItemText primary={type} secondary={null} />
      {open && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={() => remove(type)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  ));

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText primary="Vehicle Type settings" secondary={null} />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Divider />

      {open && (
        <>
          <div className={classes.form}>
            <AddVehicleTypesFormComponent
              types={types}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
            />
          </div>
          <Divider />
        </>
      )}

      <List>{render}</List>
    </div>
  );
}

export function VehicleTypesComponent({ types }) {
  const classes = useStyles();

  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Updated'
  });

  const showSnackbar = (status = 'success', description = 'Updated') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, type) => {
    if (type === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const openBackdrop = () => {
    setBackdrop(true);
  };

  return (
    <div className={classes.root}>
      <VehicleTypes
        types={types}
        openBackdrop={openBackdrop}
        closeBackdrop={closeBackdrop}
        showSnackbar={showSnackbar}
      />

      <>
        <Backdrop
          className={classes.backdrop}
          open={backdrop}
          invisible={false}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </div>
  );
}
