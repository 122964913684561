import {
  APIKEY_LOAD_REQUEST,
  APIKEY_LOAD_SUCCESS,
  APIKEY_LOAD_FAILURE
} from '../types/apikey-types';

const initialState = {
  loading: false,
  error: null,
  key: null
};

function apikey(state = initialState, action: any = null) {
  switch (action.type) {
    case APIKEY_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case APIKEY_LOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case APIKEY_LOAD_SUCCESS:
      return {
        ...state,
        key: action.payload,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default apikey;
