import { Region } from '../entities/region-entity';
import { RegionRepository } from '../repositories/region-repository';

interface RegionUsecase {
  CreateRegion(name: string): Promise<Region>;
  GetRegions(): Promise<Region[]>;
  GetRegion(region_id: string): Promise<Region>;
  EditRegion(region_id: string, name: string): Promise<Region>;
  AddHub(region_id: string, hub_id: string): Promise<Region>;
  RemoveHub(region_id: string, hub_id: string): Promise<Region>;
}

export class RegionUsecaseImpl implements RegionUsecase {
  regionRepository: RegionRepository;

  constructor(ur: RegionRepository) {
    this.regionRepository = ur;
  }

  CreateRegion(name: string): Promise<Region> {
    return this.regionRepository.CreateRegion(name);
  }

  GetRegions(): Promise<Region[]> {
    return this.regionRepository.GetRegions();
  }

  GetRegion(region_id: string): Promise<Region> {
    return this.regionRepository.GetRegion(region_id);
  }

  EditRegion(region_id: string, name: string): Promise<Region> {
    return this.regionRepository.EditRegion(region_id, name);
  }

  AddHub(region_id: string, hub_id: string): Promise<Region> {
    return this.regionRepository.AddHub(region_id, hub_id);
  }

  RemoveHub(region_id: string, hub_id: string): Promise<Region> {
    return this.regionRepository.RemoveHub(region_id, hub_id);
  }
}
