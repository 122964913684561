import { HUBS_LOAD_REQUEST, HUBS_LOAD_SUCCESS, HUBS_LOAD_FAILURE } from '../types/hubs-types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  hubs: []
};

function hubs(state = initialState, action: any = null) {
  switch (action.type) {
    case HUBS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case HUBS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case HUBS_LOAD_SUCCESS:
      return {
        ...state,
        hubs: action.payload,
        success: true,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default hubs;
