// @ts-check
import React from 'react';

import { useDispatch } from 'react-redux';
import { editVehicleTypesAction } from '../redux/actions/settings-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function AddVehicleTypesForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('type')}
        id="outlined-full-width"
        label="Vehicle type"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <div className={classes.formButtons}>
        <>
          <Button
            className={classes.margin}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            Add Vehicle Type
          </Button>
        </>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  type: Yup.string('Enter vehicle type').required('Vehicle type is required')
});

export function AddVehicleTypesFormComponent({ types, openBackdrop, closeBackdrop, showSnackbar }) {
  const dispatch = useDispatch();

  const values = { type: '' };

  const submit = (data, actions) => {
    let existingType = types.find(
      (x) =>
        x.replace(/[^A-Z0-9]/gi, '').toLowerCase() ===
        data.type.replace(/[^A-Z0-9]/gi, '').toLowerCase()
    );

    if (existingType) {
      closeBackdrop();
      showSnackbar('error', 'This vehicle type already exists');
      return;
    }

    const { resetForm } = actions;
    openBackdrop();
    const props = { resetForm, closeBackdrop, showSnackbar };
    dispatch(editVehicleTypesAction([...types, data.type], props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <AddVehicleTypesForm {...props} />}
    </Formik>
  );
}
