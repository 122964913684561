// @ts-check
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton/IconButton';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemSecondaryAction } from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';

import { config } from '../../../../core/config/config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
    // marginTop: theme.spacing(0),
    // padding: theme.spacing(0),
    // marginBottom: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing(0)
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
}));

export function ApiDocumentationComponent() {
  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          {/* <ListItemIcon>
            <IconButton onClick={() => history.push('/')}>
              <ArrowBackIcon />
            </IconButton>
          </ListItemIcon> */}
          <ListItemText
            primary={<small>Documentation</small>}
            secondary={'Api to integrate programmatically'}
          ></ListItemText>

          <ListItemSecondaryAction>
            <a
              className={classes.link}
              href={config.uri.api_documentation}
              rel="noopener noreferrer"
              target="_blank"
            >
              <IconButton edge="end">
                <DescriptionIcon />
              </IconButton>
            </a>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
}
