//@ts-check
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './core/routes/routes';
import * as serviceWorker from './serviceWorker';

import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';

import { Provider } from 'react-redux';
import store from './core/redux/store';

const APP_ENV = process.env.REACT_APP_ENV;

const firebaseConfigDev = {
  apiKey: 'AIzaSyCgiluwpE3dNxGLL_iAPaV4SKZDTm_tpME',
  authDomain: 'cb-dev-298308.firebaseapp.com',
  projectId: 'cb-dev-298308',
  storageBucket: 'cb-dev-298308.appspot.com',
  messagingSenderId: '997363095290',
  appId: '1:997363095290:web:ac9608e141fa8ad93ddb43',
  measurementId: 'G-3P6K67GJB2',
  experimentalForceLongPolling: true
};

const firebaseConfigProd = {
  apiKey: 'AIzaSyAw1rb33HQJAgqCt_rYRL6C_6kJNVBSGIc',
  authDomain: 'cb-prod-297913.firebaseapp.com',
  projectId: 'cb-prod-297913',
  storageBucket: 'cb-prod-297913.appspot.com',
  messagingSenderId: '136986454700',
  appId: '1:136986454700:web:e43ad1edeba6da3cd1cd09',
  measurementId: 'G-03ZPMNVXTZ',
  experimentalForceLongPolling: true
};

export const firebaseApp = initializeApp(
  APP_ENV === 'production' ? firebaseConfigProd : firebaseConfigDev
);

export const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.settings.fetchTimeoutMillis = 60000;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Routes />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
