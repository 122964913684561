// @ts-check
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import Container from '@material-ui/core/Container/Container';

import Button from '@material-ui/core/Button/Button';

import { logoutAction } from '../../../../core/redux/actions/logout-action';

import { Apps } from '../../../../core/routes/components/apps';
import { AccountDetailsComponent } from '../components/account-details-component';
import { ImageNameComponent } from '../components/image-name-component';

import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  }
}));

export function Account({ me }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      <div>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={12} justify="center">
            <ImageNameComponent me={me} />
          </Grid>
          <Grid item xs={12} md={8} justify="center">
            <AccountDetailsComponent account={me} />
            <Apps header={true} />
            <Button
              className={classes.margin}
              type="button"
              variant="contained"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={() => dispatch(logoutAction())}
            >
              Sign Out
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export function ProfilePage() {
  const classes = useStyles();

  const { me, loading } = useSelector((state) => state.me);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <>loading</>}
          {!loading && me && (
            <>
              <BackNavigationComponent page="Account" />
              <Account me={me} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
