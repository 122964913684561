// @ts-check
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';
import { getBranchesAction } from '../../../branches/presentation/redux/actions/branch-actions';
import { LoadingComponent } from '../components/loading-component';
import { ApiDocumentationComponent } from '../components/api-documentation-component';
import { BranchesTableComponent } from '../components/branches-table-component';
import Typography from '@material-ui/core/Typography';
import { ApiKeyComponent } from '../components/api-key-component';
import { WebhooksComponent } from '../components/webhooks-component';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  heading: {
    margin: theme.spacing(2)
  }
}));

export default function DeveloperPage() {
  const classes = useStyles();

  const { branches, loading } = useSelector((state) => state.branches);
  const { me } = useSelector((state) => state.me);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranchesAction());
  }, [dispatch]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && (
            <>
              <BackNavigationComponent page="Developer" uri="/" />
              <ApiDocumentationComponent />
              <ApiKeyComponent />

              {me && <WebhooksComponent organization={me.organization} />}

              <Typography
                variant="subtitle2"
                component="h3"
                align="center"
                className={classes.heading}
              >
                <small>
                  <b>Branches</b>
                </small>
              </Typography>

              <BranchesTableComponent branches={branches} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
