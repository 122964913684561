import { removeHub } from '../../../regions/data/datasources/remove-hub';
import { Hub } from '../../domain/entities/hub-entity';
import { HubRepository } from '../../domain/repositories/hub-repository';
import { createHub } from '../datasources/create-hub';
import { editHub } from '../datasources/edit-hub';
import { getHub } from '../datasources/get-hub';
import { getHubs } from '../datasources/get-hubs';

export class HubRepositoryImpl implements HubRepository {
  async CreateHub(name: string): Promise<Hub> {
    const result = await createHub(name);

    return new Hub(result, result.branches, result.regions);
  }

  async EditHub(hub_id: string, name: string): Promise<Hub> {
    const result = await editHub(hub_id, name);

    return new Hub(result, result.branches, result.regions);
  }

  async GetHub(hub_id: string): Promise<Hub> {
    const result = await getHub(hub_id);

    return new Hub(result, result.branches, result.regions);
  }

  async GetHubs(): Promise<Hub[]> {
    const result = await getHubs();

    return result.map((hub: any) => new Hub(hub, hub.branches));
  }

  async RemoveHubFromRegion(region_id: string, hub_id: string): Promise<Hub> {
    await removeHub(region_id, hub_id);
    const result = await getHub(hub_id);

    return new Hub(result, result.branches, result.regions);
  }
}
