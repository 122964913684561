// @ts-check
import React from 'react';

import { useDispatch } from 'react-redux';
import { editPoolingGeofenceAction } from '../redux/actions/settings-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, ListItemText, Switch } from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  },
  switchButton: {
    float: 'left',
    position: 'absolute',
    left: '-90px'
  },
  geofencingText: {
    float: 'left',
    position: 'absolute',
    left: '-210px'
  }
}));

function EditPoolingGeofenceForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <RadioGroup aria-label="enabled" {...formikProps('enabled')}>
          <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
          <FormControlLabel value={'false'} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      <ListItemText secondary="Drivers within this radius will be prioritised to receive trips above drivers outside this radius." />
      <TextField
        {...formikProps('priority_distance')}
        id="outlined-full-width"
        label="Priority Distance"
        type="number"
        variant="outlined"
        fullWidth={true}
        inputProps={{
          'aria-label': 'priority_distance'
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
          inputProps: { min: 1 }
        }}
      />
      <ListItemText secondary="Trips will not pend to drivers automatically if they are outside the specified radius." />
      <TextField
        {...formikProps('maximum_distance')}
        id="outlined-full-width"
        label="Maximum Distance"
        type="number"
        variant="outlined"
        fullWidth={true}
        inputProps={{
          'aria-label': 'maximum_distance'
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
          inputProps: { min: 1 }
        }}
      />
      <div className={classes.formButtons}>
        <>
          <Button
            className={classes.margin}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            Save Changes
          </Button>
        </>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  enabled: Yup.boolean('Select a setting').required('Enabled value setting is required'),
  priority_distance: Yup.number('Enter priority distance')
    .required('priority distance is required')
    .min(0),
  maximum_distance: Yup.number('Enter maximum distance')
    .required('maximum distance is required')
    .min(0)
});

export function EditPoolingGeofenceFormComponent({
  pooling_geofence,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  handleClose
}) {
  const dispatch = useDispatch();

  const values = {
    enabled: String(pooling_geofence.enabled),
    priority_distance: Number(pooling_geofence.priority_distance),
    maximum_distance: Number(pooling_geofence.maximum_distance)
  };

  const submit = (data, actions) => {
    data.enabled = data.enabled === 'true' ? true : false;
    openBackdrop();
    const { resetForm } = actions;
    const props = { resetForm, closeBackdrop, showSnackbar, handleClose };
    dispatch(editPoolingGeofenceAction(data, props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditPoolingGeofenceForm {...props} />}
    </Formik>
  );
}
