// @ts-check
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getRegionAction } from '../redux/actions/region-actions';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import { LoadingComponent } from '../components/loading-component';
import { RegionInformationComponent } from '../components/region-information-component';
import { RegionMapComponent } from '../components/region-map-component';
import { HubsInRegionComponent } from '../components/hubs-in-region-component';
import { getHubsAction } from '../../../hubs/presentation/redux/actions/hub-actions';
import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: theme.spacing(3)
  },
  userHeader: {
    textAlign: 'center'
  },
  userLogo: {
    height: '10rem',
    width: '10rem',
    objectFit: 'contain'
  },
  large: {
    height: '8rem',
    width: '8rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  }
}));

function CenteredGrid({ region }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <RegionMapComponent region={region} />
      </Grid>

      <Grid item xs={12} md={8}>
        <RegionInformationComponent region={region} />
        <HubsInRegionComponent region={region} />
      </Grid>
    </Grid>
  );
}

export default function RegionPage() {
  // @ts-ignore
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { region, loading, success } = useSelector((state) => state.region);

  useEffect(() => {
    dispatch(getRegionAction(id));
    dispatch(getHubsAction());
  }, [dispatch, id]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Region"
                uri="/regions"
                path={[
                  {
                    page: 'Home',
                    uri: '/'
                  },
                  {
                    page: 'Regions',
                    uri: '/regions'
                  }
                ]}
              />
              <CenteredGrid region={region} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
