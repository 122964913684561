import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      marginBottom: theme.spacing(1)
    }
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  marginCancel: {
    marginBottom: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  modal: {
    width: 680,
    backgroundColor: '#fff',
    position: 'absolute',
    borderRadius: 5,
    top: '25%',
    left: 0,
    right: 0,
    margin: '0 auto',
    padding: '0 20px'
  },
  formButtons: {
    marginTop: theme.spacing(2)
  },
  warningText: {
    color: '#ea3323'
  },
  modalActions: {
    marginBottom: '20px',
    textAlign: 'center'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  granted: {
    color: '#4caf50'
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));
