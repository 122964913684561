import {
  REGION_LOAD_REQUEST,
  REGION_LOAD_SUCCESS,
  REGION_LOAD_FAILURE
} from '../types/region-types';

const initialState = {
  loading: false,
  success: false,
  error: null,
  region: null
};

function region(state = initialState, action: any = null) {
  switch (action.type) {
    case REGION_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };

    case REGION_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      };

    case REGION_LOAD_SUCCESS:
      return {
        ...state,
        region: action.payload,
        loading: false,
        success: true,
        error: null
      };

    default:
      return state;
  }
}

export default region;
