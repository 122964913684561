export const SETTINGS_LOAD_REQUEST = 'SETTINGS_LOAD_REQUEST';
export const SETTINGS_LOAD_SUCCESS = 'SETTINGS_LOAD_SUCCESS';
export const SETTINGS_LOAD_FAILURE = 'SETTINGS_LOAD_FAILURE';

export type TerminateOrders = {
  enabled: boolean;
  max_age: number;
};

export type OrderHistory = {
  hours: number;
  type: string;
};

export type AutomaticReturns = {
  enabled: boolean;
};
