import { Branch } from '../../../branches/domain/entities/branch-entity';
import { Region } from '../../../regions/domain/entities/region-entity';

export class Hub {
  id: string;
  name: string;
  branches?: Branch[];
  regions?: Region[];

  constructor(hub: any, branches: any = undefined, regions: any = undefined) {
    this.id = hub.id;
    this.name = hub.name;
    this.branches = branches && branches.map((branch: any) => new Branch(branch));
    this.regions = regions && regions.map((region: any) => new Region(region));
  }
}
