import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import { logger } from 'redux-logger';

import token from './reducers/token-reducers';
import loading from './reducers/loading-reducers';

import login from '../../features/login/presentation/redux/reducers/login-reducers';
import me from '../../features/me/presentation/redux/reducers/me-reducer';
import organization from '../../features/login/presentation/redux/reducers/organization-reducers';

import user from '../../features/users/presentation/redux/reducers/user-reducer';
import upload from '../../features/users/presentation/redux/reducers/upload-reducer';
import users from '../../features/users/presentation/redux/reducers/users-reducer';

import hub from '../../features/hubs/presentation/redux/reducers/hub-reducer';
import hubs from '../../features/hubs/presentation/redux/reducers/hubs-reducer';

import branch from '../../features/branches/presentation/redux/reducers/branch-reducer';
import branches from '../../features/branches/presentation/redux/reducers/branches-reducer';

import region from '../../features/regions/presentation/redux/reducers/region-reducer';
import regions from '../../features/regions/presentation/redux/reducers/regions-reducer';

import apikey from '../../features/developer/presentation/redux/reducers/apikey-reducer';
import settings from '../../features/settings/presentation/redux/reducers/settings-reducer';

// Setup Redux store with Thunks
const reducers: any = combineReducers({
  token,
  loading,
  me,
  login,
  organization,
  hub,
  hubs,
  branch,
  branches,
  region,
  regions,
  user,
  upload,
  users,
  apikey,
  settings
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APPLICATION_STATE') {
    state = undefined;
  }

  return reducers(state, action);
};
const store: any = createStore(rootReducer, applyMiddleware(thunk));

export default store;
export type AppState = ReturnType<typeof rootReducer>;
