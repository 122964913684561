// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { HubsPermissionsComponent } from './hubs-permissions-component';
import { RegionsPermissionsComponent } from './regions-permissions-component';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  heading: {
    margin: theme.spacing(2)
  }
}));

export function DOSPermissionsComponent({ user }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  return (
    <>
      <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
        <small>
          <b>DOS Permissions</b>
        </small>
      </Typography>

      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Hubs" {...a11yProps(0)} />
            <Tab label="Regions" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        {/* <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        > */}
        <>
          <TabPanel
            value={value}
            index={0}
            // @ts-ignore
            dir={theme.direction}
          >
            <HubsPermissionsComponent user={user} />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            // @ts-ignore
            dir={theme.direction}
          >
            <RegionsPermissionsComponent user={user} />
          </TabPanel>
        </>
        {/* </SwipeableViews> */}
      </div>
    </>
  );
}
