// @ts-check
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { BackNavigationComponent } from '../../../../core/routes/components/back-navigation-component';
import { getSettingsAction } from '../redux/actions/settings-actions';
import { LoadingComponent } from '../components/loading-component';
import { RatingsAbandonmentComponent } from '../components/ratings-abandonment-component';
import { AbandonmentReasonsComponent } from '../components/abandonment-reasons-component';
import { PartialDeliveryReasonsComponent } from '../components/partial-delivery-reasons-component';
import { VehicleTypesComponent } from '../components/vehicle-types-component';
import { ClientSettingComponent } from '../components/client-settings-component';
import { ClusterSettingsComponent } from '../components/cluster-settings-component';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  heading: {
    margin: theme.spacing(2)
  }
}));

export default function SettingsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { settings, loading } = useSelector((state) => state.settings);
  const { me } = useSelector((state) => state.me);
  useEffect(() => {
    dispatch(getSettingsAction());
  }, [dispatch]);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && settings && me && (
            <>
              <BackNavigationComponent page="Settings" uri="/" />
              <ClientSettingComponent
                pooling={settings.pooling}
                route_optimisation={settings.route_optimisation}
                sms_enabled={settings.sms_enabled}
                terminate_orders={settings.terminate_orders}
                qr_typing_rights={settings.qr_typing_rights}
                qr_typing={settings.qr_typing}
                geofence={settings.geofence}
                pooling_geofence={settings.pooling_geofence}
                recipient_name={settings.recipient_name}
                trip_sequencing={settings.trip_sequencing}
                organization={me.organization}
                order_history={settings.order_history}
                can_create_trip={settings.can_create_trip}
                can_retry_abandoned={settings.can_retry_abandoned}
                automatic_returns={settings.automatic_returns}
              />
              <ClusterSettingsComponent cluster={settings.cluster} organization={me.organization} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <RatingsAbandonmentComponent ratings={settings.ratings} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <AbandonmentReasonsComponent reasons={settings.abandonment_reasons} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <VehicleTypesComponent types={settings.vehicle_types} />
                </Grid>
                {me.organization.client_type === 'LOGISTICS' && (
                  <Grid item xs={12} md={4}>
                    <PartialDeliveryReasonsComponent reasons={settings.partial_delivery_reasons} />
                  </Grid>
                )}
                {/* <Grid item xs={12} md={4}>
                  <TaskPauseReasonsComponent reasons={settings.task_pause_reasons} />
                </Grid> */}
              </Grid>
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
