import React from 'react';
import { isValidNumber, validatePhoneNumberLength } from 'libphonenumber-js';

import { useDispatch, useSelector } from 'react-redux';
import { editUserPhoneAction } from '../redux/actions/user-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function EditUserPhoneForm(props) {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.loading);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, handleClose } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('mobile_no')}
        id="outlined-full-width"
        label="Phone no."
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Save Changes
            </Button>

            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  mobile_no: Yup.string('Enter user phone no.')
    .test(
      'Phone Test',
      'Please enter a valid phone number including the country code.',
      (value) => {
        try {
          return isValidNumber(value) && validatePhoneNumberLength(value) === undefined;
        } catch (err) {
          return true;
        }
      }
    )
    .required('Phone no. is required')
});

export function EditUserPhoneFormComponent({ user, handleClose }) {
  const dispatch = useDispatch();

  const values = { mobile_no: user.mobile_no };

  const submit = (data) => {
    const props = { handleClose };
    dispatch(editUserPhoneAction(user.id, data.mobile_no, props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditUserPhoneForm {...props} handleClose={handleClose} />}
    </Formik>
  );
}
