// @ts-check
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { ListItemSecondaryAction } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography/Typography';

import { MapComp } from './map-address';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

function BranchAddress({ branch }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemText primary={'Address'} secondary={branch.address}></ListItemText>

          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
            <small>
              <b>Edit Address</b>
            </small>
          </Typography>
        </>
      )}

      {open && <MapComp branch={branch} handleClose={handleClose} />}
    </>
  );
}

export function BranchAddressComponent({ branch }) {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
        <small>
          <b>Address</b>
        </small>
      </Typography>
      <BranchAddress branch={branch} />
    </div>
  );
}
