// @ts-check
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {
  CircularProgress,
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';

import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import { getApiKeyAction } from '../redux/actions/developer-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  heading: {
    margin: theme.spacing(2)
  },
  fab: {
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Key() {
  const classes = useStyles();
  const { key, loading } = useSelector((state) => state.apikey);
  const dispatch = useDispatch();

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <VpnKeyOutlinedIcon />
        </ListItemAvatar>

        <ListItemText
          primary={
            key ? (
              <span>{key}</span>
            ) : (
              <span>
                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
              </span>
            )
          }
          secondary={null}
        ></ListItemText>

        <ListItemSecondaryAction>
          {!loading && (
            <IconButton edge="end" aria-label="vie key" onClick={() => dispatch(getApiKeyAction())}>
              <VisibilityIcon />
            </IconButton>
          )}
          {loading && (
            <IconButton edge="end" aria-label="vie key">
              <CircularProgress color="inherit" size={20} />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

export function ApiKeyComponent() {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="subtitle2" component="h3" align="center" className={classes.heading}>
        <small>
          <b>Api Key</b>
        </small>
      </Typography>

      <Key />
    </div>
  );
}
