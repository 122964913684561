import { Settings } from '../../domain/entities/settings-entity';
import { SettingsRepository } from '../../domain/repositories/settings-repository';
import { editCluster } from '../datasources/edit-cluster-settings';
import { editScan } from '../datasources/edit-scan-settings';
import { editPooling } from '../datasources/edit-pooling-settings';
import { editRatings } from '../datasources/edit-rating-settings';
import { editAbandonmentReasons } from '../datasources/edit-abandonment-reasons-settings';
import { editPartialDeliveryReasons } from '../datasources/edit-partial-delivery-reasons-settings';
import { editTaskPauseReasons } from '../datasources/edit-task-pause-reasons';
import { editSms } from '../datasources/edit-sms-settings';
import { editTerminateOrders } from '../datasources/edit-terminate-orders-settings';
import { getSettings } from '../datasources/get-settings';
import { editVehicleTypes } from '../datasources/edit-vehicle-types-settings';
import { editQRTyping } from '../datasources/edit-qr-typing-settings';
import { editGeofence } from '../datasources/edit-geofence';
import { editRecipientName } from '../datasources/edit-recipient-name-settings';
import { EditPoolingGeofence } from '../datasources/edit-pooling-geofence';
import { editRouteOptimisation } from '../datasources/edit-route-optimisation-settings';
import { editTripSequencing } from '../datasources/edit-trip-sequencing-settings';
import {
  AutomaticReturns,
  OrderHistory,
  TerminateOrders
} from '../../presentation/redux/types/settings-types';
import { editOrderHistory } from '../datasources/edit-order-history-setting';
import { editTripCreateFromApp } from '../datasources/edit-trip-app-create';
import { editCanRetryAbandoned } from '../datasources/edit-can-retry-abandoned';
import { editAutomaticReturns } from '../datasources/edit-automatic-returns-settings.ts';

export class SettingsRepositoryImpl implements SettingsRepository {
  async GetSettings(): Promise<Settings> {
    const result = await getSettings();

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditRouteOptimisation(route_optimisation: object): Promise<Settings> {
    const result = await editRouteOptimisation(route_optimisation);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditRatings(ratings: string[]): Promise<Settings> {
    const result = await editRatings(ratings);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditAbandonmentReasons(reasons: string[]): Promise<Settings> {
    const result = await editAbandonmentReasons(reasons);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditPartialDeliveryReasons(reasons: string[]): Promise<Settings> {
    const result = await editPartialDeliveryReasons(reasons);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditTaskPauseReasons(reasons: string[]): Promise<Settings> {
    const result = await editTaskPauseReasons(reasons);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditPooling(algorithm: string): Promise<Settings> {
    const result = await editPooling(algorithm);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditCluster(
    distance: number,
    size: number,
    lock_strategy: string,
    lock_time: number,
    collection_time_range: number
  ): Promise<Settings> {
    const result = await editCluster(
      distance,
      size,
      lock_strategy,
      lock_time,
      collection_time_range
    );

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditSms(sms_enabled: boolean): Promise<Settings> {
    const result = await editSms(sms_enabled);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditVehicleTypes(types: string[]): Promise<Settings> {
    const result = await editVehicleTypes(types);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditTerminateOrders(terminate_orders: TerminateOrders): Promise<Settings> {
    const result = await editTerminateOrders(terminate_orders);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditQRTyping(qr_typing: boolean): Promise<Settings> {
    const result = await editQRTyping(qr_typing);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditGeofence(geofence: object): Promise<Settings> {
    const result = await editGeofence(geofence);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditPoolingGeofence(pooling_geofence: object): Promise<Settings> {
    const result = await EditPoolingGeofence(pooling_geofence);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditRecipientName(recipient_name: object): Promise<Settings> {
    const result = await editRecipientName(recipient_name);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditTripSequencing(trip_sequencing: object): Promise<Settings> {
    const result = await editTripSequencing(trip_sequencing);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditOrderHistory(order_history: OrderHistory): Promise<Settings> {
    const result = await editOrderHistory(order_history);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditTripAppCreate(can_create_trip: object): Promise<Settings> {
    const result = await editTripCreateFromApp(can_create_trip);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditCanRetryAbandoned(can_retry_abandoned: object): Promise<Settings> {
    const result = await editCanRetryAbandoned(can_retry_abandoned);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }

  async EditAutomaticReturns(automatic_returns: AutomaticReturns): Promise<Settings> {
    const result = await editAutomaticReturns(automatic_returns);

    return new Settings(
      result.scan,
      result.cluster,
      result.pooling,
      result.ratings,
      result.abandonment_reasons,
      result.partial_delivery_reasons,
      result.task_pause_reasons,
      result.sms_enabled,
      result.vehicle_types,
      result.terminate_orders,
      result.qr_typing,
      result.qr_typing_rights,
      result.secondary_driver_rights,
      result.use_third_party_api,
      result.partners,
      result.geofence,
      result.pooling_geofence,
      result.recipient_name,
      result.route_optimisation,
      result.trip_sequencing,
      result.order_history,
      result.can_create_trip,
      result.can_retry_abandoned,
      result.automatic_returns
    );
  }
}
