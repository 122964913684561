import { IPaymentProviderState } from './payment_providers.interface';

export const paymentFieldsActionTypes = {
  SET_BACKDROP: 'SET_BACKDROP',
  OPEN_SNACKBAR: 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_OPEN: 'SET_OPEN'
};

export const paymentFieldsInitialState: IPaymentProviderState = {
  backdrop: false,
  snackbar: false,
  message: {
    status: 'success',
    description: 'Custom fields updated'
  },
  open: false
};

export const paymentFieldsReducer = (
  state: IPaymentProviderState = paymentFieldsInitialState,
  action: any
) => {
  switch (action.type) {
    case paymentFieldsActionTypes.SET_BACKDROP:
      return {
        ...state,
        backdrop: action.payload
      };
    case paymentFieldsActionTypes.OPEN_SNACKBAR:
      return {
        ...state,
        snackbar: true,
        backdrop: false,
        message: action.payload
      };
    case paymentFieldsActionTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        message: paymentFieldsInitialState.message,
        snackbar: false
      };
    case paymentFieldsActionTypes.SET_OPEN:
      return {
        ...state,
        open: action.payload
      };
    default:
      return state;
  }
};
