import { Me } from '../../../me/domain/entities/me-entity';
import { ApiKey } from '../../domain/entities/api-key-entity';
import { DeveloperRepository } from '../../domain/repositories/developer-repository';
import { getApiKey } from '../datasources/get-api-key';
import { updateWebhooks } from '../datasources/update-webhooks';

export class DeveloperRepositoryImpl implements DeveloperRepository {
  async UpdateWebhook(
    location_webhook: string,
    general_webhook: string,
    order_events: object,
    trip_events: object,
    task_events: object,
    driver_events: object,
    wallet_events: object
  ): Promise<Me> {
    const result = await updateWebhooks(
      location_webhook,
      general_webhook,
      order_events,
      trip_events,
      task_events,
      driver_events,
      wallet_events
    );
    return new Me(result);
  }

  async GetApiKey(): Promise<ApiKey> {
    const result = await getApiKey();

    return new ApiKey(result.key);
  }
}
