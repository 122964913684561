import React from 'react';
import { isValidNumber, validatePhoneNumberLength } from 'libphonenumber-js';

import { useDispatch, useSelector } from 'react-redux';
import { editBranchAction } from '../redux/actions/branch-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function EditBranchForm(props) {
  const classes = useStyles();
  const { error, loading } = useSelector((state) => state.loading);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, handleClose } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('name')}
        id="outlined-full-width"
        label="Name"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('contact')}
        id="outlined-full-width"
        label="Contact no."
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('store_code')}
        id="outlined-full-width"
        label="Store code"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps('dashboard_url')}
        id="outlined-full-width"
        label="Dashboard url"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
            >
              Save Changes
            </Button>

            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  name: Yup.string('Enter branch name').required('Branch name is required'),
  contact: Yup.string('Enter branch contact no.')
    .test(
      'Phone Test',
      'Enter a valid phone no., with a valid iso code. (e.g +9723656759)',
      (value) => {
        try {
          return isValidNumber(value) && validatePhoneNumberLength(value) === undefined;
        } catch (err) {
          return true;
        }
      }
    )
    .required('Contact no. is required')
});

export function EditBranchFormComponent({ branch, handleClose }) {
  const dispatch = useDispatch();

  const values = {
    name: branch.name,
    contact: branch.contact,
    store_code: branch.store_code,
    dashboard_url: branch.dashboard_url,
    address: ''
  };

  const submit = (data) => {
    const props = { handleClose };

    dispatch(
      editBranchAction(
        branch.id,
        data.name,
        data.store_code,
        data.dashboard_url,
        data.contact,
        props
      )
    );
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditBranchForm {...props} handleClose={handleClose} />}
    </Formik>
  );
}
