// @ts-check
import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader as MapLoader } from 'google-maps';
import { useSelector } from 'react-redux';

import throttle from 'lodash/throttle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';

import { Button, CircularProgress } from '@material-ui/core';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  map: {
    marginTop: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(4)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

// @ts-ignore
export const CreateBranchAddressComponent = ({ submit }) => {
  const classes = useStyles();
  const history = useHistory();

  const { error, loading } = useSelector((state) => state.branch);

  const mapRef = useRef(null);
  const [marker, setMarker] = useState(undefined);
  const [map, setMap] = useState(undefined);
  const [google, setGoogle] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);
  const [address, setAddress] = useState({
    valid: false
  });

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        // @ts-ignore
        autocomplete.getPlacePredictions(request, callback);
      }, 200),
    [autocomplete]
  );

  const mapping = useCallback(async () => {
    const geopoint = { lat: -26.2041, lng: 28.0473 };

    const options = {
      libraries: ['places', 'geometry']
    };
    const loader = new MapLoader(GOOGLE_MAPS_API_KEY, options);
    const google = await loader.load();
    setLoaded(true);

    if (!loaded && !mapRef.current) {
      return;
    }

    // @ts-ignore
    const map = new google.maps.Map(mapRef.current, {
      center: geopoint,
      zoom: 13,
      scaleControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false
    });

    const marker = new google.maps.Marker({
      map: map,
      // icon: icon,
      anchorPoint: new google.maps.Point(0, 0),
      draggable: false,
      animation: google.maps.Animation.DROP
    });

    // @ts-ignore
    const autocomplete = new google.maps.places.AutocompleteService();

    // @ts-ignore
    setMarker(marker);
    // @ts-ignore
    setMap(map);
    // @ts-ignore
    setGoogle(google);
    // @ts-ignore
    setAutocomplete(autocomplete);
  }, [loaded]);

  useEffect(() => {
    mapping();
  }, [mapping]);

  useEffect(() => {
    let active = true;

    if (!loaded) {
      return undefined;
    }

    if (inputValue === '') {
      setAddress({
        valid: false
      });
      // @ts-ignore
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        // @ts-ignore
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, loaded]);

  const getPlace = async (result) => {
    if (loaded && result) {
      const request = {
        placeId: result.place_id
      };
      // @ts-ignore
      const service = new google.maps.places.PlacesService(map);

      service.getDetails(request, callback);
    }
  };

  function callback(place, status) {
    // @ts-ignore
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      // @ts-ignore
      marker.setPosition(place.geometry.location);
      // @ts-ignore
      marker.setVisible(true);

      // @ts-ignore
      map.fitBounds(place.geometry.viewport);

      const geocode = JSON.parse(JSON.stringify(place.geometry.location));

      const result = {
        valid: true,
        formatted_address: place['formatted_address'],
        location: {
          latitude: geocode.lat,
          longitude: geocode.lng
        }
      };
      // @ts-ignore
      setAddress(result);
    }
  }

  return (
    <>
      <Autocomplete
        // @ts-ignore
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          // @ts-ignore
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          getPlace(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => <TextField {...params} label="Address" variant="outlined" />}
        renderOption={(option) => {
          // @ts-ignore
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            // @ts-ignore
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {
                    // @ts-ignore
                    option.structured_formatting.secondary_text
                  }
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />

      <div className={classes.map}>
        <div>
          <div
            id="map"
            // @ts-ignore
            ref={mapRef}
            style={{ height: '15em', width: '100%' }}
          ></div>
        </div>
      </div>

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={true}
              disabled={!address.valid}
              onClick={() => submit(address)}
            >
              Save Changes
            </Button>

            <Button
              className={classes.margin}
              variant="outlined"
              size="large"
              color="secondary"
              fullWidth={true}
              onClick={() => history.push('/branches')}
            >
              Cancel
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </>
  );
};
