// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from 'react-router-dom';
import { IconButton, ListItemSecondaryAction } from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  }
}));

export function UsersListComponent({ users }) {
  const classes = useStyles();
  const history = useHistory();

  const n = users.length - 1;
  const renderUsers = users.map((user, index) => {
    return (
      <div key={index}>
        <ListItem
          alignItems="flex-start"
          button
          onClick={() => history.push(`/users/${user.id}/view`)}
        >
          <ListItemAvatar>
            <Avatar
              alt={`${user.firstname} ${user.lastname}`}
              src={user.image || '/static/images/avatar/1.jpg'}
            />
          </ListItemAvatar>
          <ListItemText
            primary={`${user.firstname} ${user.lastname}`}
            secondary={<React.Fragment>{user.email}</React.Fragment>}
          />

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={() => history.push(`/users/${user.id}/view`)}
            >
              <VisibilityIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {index !== n && <Divider variant="inset" component="li" />}
      </div>
    );
  });

  return <List className={classes.root}>{renderUsers}</List>;
}
