import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';
import store from '../../../../core/redux/store';

export const updateWebhooks = async (
  location_webhook: string,
  general_webhook: string,
  order_events: object,
  trip_events: object,
  task_events: object,
  driver_events: object,
  wallet_events: object
): Promise<any> => {
  try {
    const state = store.getState();
    const token = state.token.token;

    const uri = `${config.uri.base_url}/developers/update/webhooks`;
    const options = {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        location_webhook,
        general_webhook,
        order_events,
        trip_events,
        task_events,
        driver_events,
        wallet_events
      })
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Something went wrong');
    }

    return result;
  } catch (err) {
    throw err;
  }
};
