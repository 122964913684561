// @ts-check
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';

import Grid from '@material-ui/core/Grid';

import { EditPoolingFormComponent } from './edit-pooling-settings-form-component';

import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarComponent } from '../../../../core/utils/components/snackbar-component';
import { Divider } from '@material-ui/core';
import { EditRouteOptimisationFormComponent } from './edit-route-optimisation-form-component';
import { EditRecipientNameFormComponent } from './edit-recipient-name-form-component';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { EditSmsFormComponent } from './edit-sms-settings-form-component';
import { EditTerminateOrdersFormComponent } from './edit-terminate-orders-settings-form-component';
import { EditGeofenceFormComponent } from './edit-geofence-settings-form-component';
import { EditQRTypingFormComponent } from './edit-qr-typing-settings-form-component';
import { EditPoolingGeofenceFormComponent } from './edit-driver-pooling-geofence-settings-form-component';
import { EditTripSequencingFormComponent } from './edit-trip-sequencing-form-component';
import { ClientType } from '../../../../core/types/organization';
import { EditOrderHistoryFormComponent } from './edit-order-history-settings-form-component';
import {
  EditCanRetryAbandonedFormComponent,
  EditTripAppCreateFormComponent
} from './edit-trip-app-create-form-component';
import { EditAutomaticReturnsFormComponent } from './edit-automatic-returns-settings';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
    // maxWidth: 752
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  form: {
    margin: theme.spacing(2, 3, 2, 3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function RouteOptimisation({
  route_optimisation,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  isLogistics
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary="Route Optimisation"
            secondary={route_optimisation ? route_optimisation.priority : 'Default'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditRouteOptimisationFormComponent
              route_optimisation={route_optimisation}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
              isLogistics={isLogistics}
            />
          </div>
        </>
      )}
    </div>
  );
}

function Pooling({ pooling, openBackdrop, closeBackdrop, showSnackbar }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText primary="Driver pooling algorithm" secondary={pooling.algorithm} />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditPoolingFormComponent
              pooling={pooling}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
}

function SMS({ sms_enabled, openBackdrop, closeBackdrop, showSnackbar, isLogistics }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const type = isLogistics ? 'Task' : 'Order';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary={`${type} tracking page`}
            secondary={!sms_enabled ? 'disabled' : 'enabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditSmsFormComponent
              sms_enabled={sms_enabled}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
}

function TerminateOrders({
  terminate_orders,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  isLogistics
}) {
  const classes = useStyles();
  const type = isLogistics ? 'Tasks' : 'Orders';

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary={`Automate Terminate ${type}`}
            secondary={terminate_orders.enabled ? 'enabled' : 'disabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditTerminateOrdersFormComponent
              terminate_orders={terminate_orders}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
              isLogistics={isLogistics}
            />
          </div>
        </>
      )}
    </div>
  );
}

function OrderHistory({ order_history, openBackdrop, closeBackdrop, showSnackbar, isLogistics }) {
  const classes = useStyles();
  const type = isLogistics ? 'Task' : 'Order';
  if (order_history === undefined) {
    order_history = {
      hours: 168
    };
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary={`Management Console ${type} Filters`}
            secondary={`${order_history.hours} hours`}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditOrderHistoryFormComponent
              order_history={order_history}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
              isLogistics={isLogistics}
            />
          </div>
        </>
      )}
    </div>
  );
}

function AutomaticReturns({ automatic_returns, openBackdrop, closeBackdrop, showSnackbar }) {
  const classes = useStyles();
  if (automatic_returns === undefined) {
    automatic_returns = {
      enabled: false
    };
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary={`Automatic Returns`}
            secondary={automatic_returns.enabled ? 'enabled' : 'disabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditAutomaticReturnsFormComponent
              automatic_returns={automatic_returns}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
}

function Geofence({ geofence, openBackdrop, closeBackdrop, showSnackbar, isLogistics }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary="Geofence settings"
            secondary={!geofence?.enabled ? 'disabled' : 'enabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditGeofenceFormComponent
              geofence={geofence}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
              isLogistics={isLogistics}
            />
          </div>
        </>
      )}
    </div>
  );
}

function PoolingGeofence({ pooling_geofence, openBackdrop, closeBackdrop, showSnackbar }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary="Driver Pooling Geofence settings"
            secondary={!pooling_geofence?.enabled ? 'disabled' : 'enabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditPoolingGeofenceFormComponent
              pooling_geofence={pooling_geofence}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
}

function RecipientName({ recipient_name, openBackdrop, closeBackdrop, showSnackbar }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let message = 'disabled';
  if (recipient_name.selected.includes('otp') && recipient_name.selected.includes('sog')) {
    message = 'enabled: SOG & OTP';
  } else if (recipient_name.selected.includes('otp')) {
    message = 'enabled: OTP';
  } else if (recipient_name.selected.includes('sog')) {
    message = 'enabled: SOG';
  }

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary="Recipient Name Typing"
            secondary={recipient_name.enabled ? message : 'disabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditRecipientNameFormComponent
              recipient_name={recipient_name}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
}

function QRTyping({ qr_typing, openBackdrop, closeBackdrop, showSnackbar }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary="Enable QR Typing"
            secondary={!qr_typing ? 'disabled' : 'enabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditQRTypingFormComponent
              qr_typing={qr_typing}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
}

function TripSequencing({ trip_sequencing, openBackdrop, closeBackdrop, showSnackbar }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary="Driver Trip Re-Sequencing"
            secondary={!trip_sequencing.enabled ? 'disabled' : 'enabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditTripSequencingFormComponent
              trip_sequencing={trip_sequencing}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
}

function TripCreateFromApp({ can_create_trip, openBackdrop, closeBackdrop, showSnackbar }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary="Scan-in to create trip"
            secondary={!can_create_trip.enabled ? 'disabled' : 'enabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditTripAppCreateFormComponent
              can_create_trip={can_create_trip}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
}

function CanRetryAbandoned({ can_retry_abandoned, openBackdrop, closeBackdrop, showSnackbar }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.demo}>
      <List>
        <ListItem>
          <ListItemText
            primary="Allow driver to retry abandoned task"
            secondary={!can_retry_abandoned.enabled ? 'disabled' : 'enabled'}
          />
          <ListItemSecondaryAction>
            {!open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClickOpen}>
                <EditIcon />
              </IconButton>
            )}
            {open && (
              <IconButton edge="end" aria-label="comments" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {open && (
        <>
          <Divider />
          <div className={classes.form}>
            <EditCanRetryAbandonedFormComponent
              can_retry_abandoned={can_retry_abandoned}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              handleClose={handleClose}
            />
          </div>
        </>
      )}
    </div>
  );
}

export function ClientSettingComponent({
  pooling,
  route_optimisation,
  sms_enabled,
  terminate_orders,
  qr_typing_rights,
  qr_typing,
  geofence,
  pooling_geofence,
  recipient_name,
  trip_sequencing,
  organization,
  order_history,
  can_create_trip,
  can_retry_abandoned,
  automatic_returns
}) {
  const classes = useStyles();
  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: 'success',
    description: 'Updated'
  });
  const isLogistics = organization.client_type === ClientType.LOGISTICS;

  const showSnackbar = (status = 'success', description = 'Updated') => {
    setSnackbar(true);
    setMessage({
      status,
      description
    });
    setBackdrop(false);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const openBackdrop = () => {
    setBackdrop(true);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <RouteOptimisation
            route_optimisation={route_optimisation}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
            isLogistics={isLogistics}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Pooling
            pooling={pooling}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TerminateOrders
            terminate_orders={terminate_orders}
            isLogistics={isLogistics}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
        {qr_typing_rights && (
          <Grid item xs={12} md={4}>
            <QRTyping
              qr_typing={qr_typing}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <PoolingGeofence
            pooling_geofence={pooling_geofence}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Geofence
            isLogistics={isLogistics}
            geofence={geofence}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RecipientName
            recipient_name={recipient_name}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
        {isLogistics && (
          <Grid item xs={12} md={4}>
            <TripSequencing
              trip_sequencing={trip_sequencing}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <OrderHistory
            order_history={order_history}
            isLogistics={isLogistics}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CanRetryAbandoned
            can_retry_abandoned={can_retry_abandoned}
            openBackdrop={openBackdrop}
            closeBackdrop={closeBackdrop}
            showSnackbar={showSnackbar}
          />
        </Grid>
        {isLogistics && (
          <>
            <Grid item xs={12} md={4}>
              <TripCreateFromApp
                can_create_trip={can_create_trip}
                openBackdrop={openBackdrop}
                closeBackdrop={closeBackdrop}
                showSnackbar={showSnackbar}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AutomaticReturns
                automatic_returns={automatic_returns}
                openBackdrop={openBackdrop}
                closeBackdrop={closeBackdrop}
                showSnackbar={showSnackbar}
              />
            </Grid>
          </>
        )}
      </Grid>

      <>
        <Backdrop
          className={classes.backdrop}
          open={backdrop}
          invisible={false}
          onClick={closeBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SnackbarComponent
          open={snackbar}
          onClose={closeSnackbar}
          severity={message.status}
          message={message.description}
        />
      </>
    </div>
  );
}
