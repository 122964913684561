// @ts-check
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { editOrderHistoryAction } from '../redux/actions/settings-actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  ListItemText,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  sectionHeading: {
    margin: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formButtons: {
    marginTop: theme.spacing(0),
    paddingBottom: theme.spacing(1)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    margin: theme.spacing(3)
  }
}));

function EditOrderHistoryForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, isLogistics } = props;

  const type = isLogistics ? 'Task' : 'Order';
  const [days, setDays] = useState(0);
  const [helperText, setHelperText] = useState(
    'that were created within the below amount of hours'
  );

  useEffect(() => {
    const hours = values.hours ? values.hours : 0;
    const days = +(hours / 24).toFixed(2);
    setDays(days);
  }, [values.hours]);

  useEffect(() => {
    if (values.type === 'delivery_time') {
      setHelperText('that are scheduled to be delivered within the below amount of hours');
    } else {
      setHelperText('that were created within the below amount of hours');
    }
  }, [values.type]);

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <div>
        <ListItemText
          secondary={`Select the default date filtering option to be used on the management console`}
        />
        <FormControl component="fieldset">
          <RadioGroup aria-label="type" {...formikProps('type')}>
            <FormControlLabel value="created_at" control={<Radio />} label={`Creation date`} />
            <FormControlLabel value="delivery_time" control={<Radio />} label="Delivery date" />
          </RadioGroup>
        </FormControl>
        <br />
        <ListItemText secondary={`${type}s ${helperText}, will show on the management console`} />
        <TextField
          {...formikProps('hours', '24')}
          id="outlined-full-width"
          type="number"
          variant="outlined"
          fullWidth={true}
          disabled={values.enabled === 'false'}
          inputProps={{
            'aria-label': 'priority_distance'
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
            inputProps: { min: 24, max: 1460 }
          }}
        />
        <ListItemText secondary={days + ' day/s'} />
      </div>

      <div className={classes.formButtons}>
        <>
          <Button
            className={classes.margin}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            Save Changes
          </Button>
        </>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  hours: Yup.number('Enter a number')
    .required('Value is required')
    .integer('Value has to be a whole number')
    .min(24, 'Value must be at least 24 hours')
    .max(1460, 'Value may not be more than 1460 hours')
});

export function EditOrderHistoryFormComponent({
  order_history,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
  handleClose,
  isLogistics
}) {
  const dispatch = useDispatch();

  const values = {
    hours: Number(order_history.hours),
    type: String(order_history.type)
  };

  const submit = (data, actions) => {
    openBackdrop();
    const { resetForm } = actions;
    const props = { resetForm, closeBackdrop, showSnackbar, handleClose };
    dispatch(editOrderHistoryAction(data, props));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <EditOrderHistoryForm {...props} isLogistics={isLogistics} />}
    </Formik>
  );
}
