interface ApiKeyInterface {
  key: string;
}

export class ApiKey implements ApiKeyInterface {
  key: string;

  constructor(key: string) {
    this.key = key;
  }
}
